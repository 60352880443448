// React
import * as React from "react";
import { Component } from "react";

// Redux
import { IGlobalState } from "../redux/store";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import {Button, ButtonBase, Grid, SvgIcon, Typography} from "@mui/material";

// Interfaces
import { WTITheme } from "../interfaces/themes/WTTheme";
import { WTIPageProps } from "../interfaces/components/props/WTIPageProps";
import {projects} from "../util/Config";
import WTContainer from "../components/containers/WTContainer";
import {WTIFile} from "../interfaces/files/WTIFile";
import DownloadIcon from "@mui/icons-material/Download";
import {WTILink} from "../interfaces/components/utility/WTILink";

// Images
import {IKImage} from 'imagekitio-react';

const styles: any = ((theme: WTITheme) => ({
	root: {
		minHeight: '-webkit-fill-available',
		height: 'auto',
	},
	section: {
		display: 'grid',
		textAlign: 'center',
		justifyContent: 'center',
		padding: theme.spacing(4),
	},
	header: {
		padding: theme.spacing(4),
	},
	image: {
		width: '100%',
		//maxHeight: 200,
	},
	imageHeader: {
		placeSelf: 'center',
		padding: theme.spacing(2),
	},
	links: {
		display: 'flex',
		justifyContent: 'space-evenly',
		fontSize: 25,
		fontFamily: theme.typography.fontFamily,
	},
	downloadButtons: {
		display: 'flex',
		justifyContent: 'space-evenly',
	}
}));

interface IProps extends WTIPageProps {
	projectIndex: number,
	test: string,
}

class ProjectPage extends Component<IProps> {
	render() {
		const {classes, languageTokens, projectIndex } = this.props;

		const projectPage = projects(languageTokens)[projectIndex];

		const hasImageAsDescriptionHeader = !!projectPage.mediaCardElement.imageAsDescriptionHeader;


		return (
			<div className={`${classes.root}`}>
				<Grid className={classes.section}>
					{hasImageAsDescriptionHeader ?
						this.headerImage(projectPage.mediaCardElement.imageAsDescriptionHeader) :
						<Typography
							variant={"h2"}
							className={classes.header}
						>{projectPage.mediaCardElement.name}
						</Typography>
					}
					<Grid item className={classes.links}>
						{projectPage.links.map((link) => this.linkButton(link))}
					</Grid>
					{
						projectPage.sections
							.map((section) =>
								<WTContainer
									key={section.descriptionHeader}
									classes={{image: classes.image}}
									section={section}
								/>)
					}
					<Grid item className={classes.downloadButtons}>
						{projectPage.downloads.map((file) => this.downloadButton(file))}
					</Grid>
				</Grid>
			</div>
		);
	}

	linkButton = (link: WTILink) => {
		return <a
			key={link.name}
			href={link.address}
			target="_blank"
		>
			{`${link.prefix} ${link.name}`}
		</a>
	}

	downloadButton = (file: WTIFile) => {
		return <Button
			key={file.name}
			onClick={() => this.onDownload(file)}
			variant="contained"
			color="primary"
		>
			<SvgIcon component={DownloadIcon} style={{paddingRight: 8,}} />
			{`${file.name} (${file.sizeText} MB)`}
		</Button>
	}


	onDownload = (file: WTIFile) => {
		const link = document.createElement("a");
		link.download = file.name
		link.href = file.path;
		link.click();
	};

	headerImage = (image: string) => {
		const {classes} = this.props;


		return <IKImage
			path={image}
			loading="lazy"
			lqip={{ active: true }}

			alt={image}
			src={image}
			className={classes.imageHeader}
		/>
	}


}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ProjectPage));
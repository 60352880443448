import LocalizedStrings from "react-localization";

export interface IBaseStrings {
	//App Names
	AppName: string;

	// Languages
	Language: string;
	english: string;
	german: string;

	// Pages
	Home: string;
	About: string;
	Contact: string;
	LegalNotice: string;

	// Utility
	loading: string;
	more: string;
	BrightMode: string;
	DarkMode: string;
	SUBMIT: string;
	Info: string;
	Download: string;
	GoogleDrive: string;

	// Legal Notice
	firstName: string;
	secondName: string;
	myCity: string;
	email: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressLine4: string;
	companyName: string;

	// Contact
	contactMeInfo: string;
	FirstName: string;
	EnterFirstName: string;
	LastName: string;
	EnterLastName: string;
	Email: string;
	EnterEmail: string;
	Subject: string;
	EnterSubject: string;
	Message: string;
	TypeYourMessageHere: string;
	sendingEmailSuccessful: string;

	// About
	Team: string;
	teamMember01Name: string;
	teamMember01Header01: string;
	teamMember01Info01: string;
	teamMember01Header02: string;
	teamMember01Info02: string;
	teamMember01Header03: string;
	teamMember01Info03: string;
	teamMember01Header04: string;
	teamMember01Info04: string;

	// Projects
	Gameplay: string;
	Trailer: string;

	// DinoKing
	dinoKingName: string;
	dinoKingThumbnailDescription: string;
	dinoKingSectionDescription_01: string;
	dinoKingSectionHeader_02: string;
	dinoKingSectionDescription_02: string;

	// Skyblock
	skyBlockName: string;
	skyBlockDescription: string;
	skyBlockSectionHeader_01: string;
	skyBlockSectionDescription_01: string;
	// Corridor
	corridorDescription: string;
	corridorName: string;
	corridorSectionHeader_01: string;
	corridorSectionDescription_01: string;
	corridorSectionHeader_02: string;
	corridorSectionDescription_02: string;

	// Door
	doorName: string;
	doorDescription: string;
	doorSectionHeader_01: string;
	doorSectionDescription_01: string;

	// Shield
	shieldName: string;
	shieldDescription: string;
	shieldSectionHeader_01: string;
	shieldSectionDescription_01: string;
	shieldSectionHeader_02: string;
	shieldSectionDescription_02: string;
	shieldSectionHeader_03: string;
	shieldSectionDescription_03: string;
	shieldSectionHeader_04: string;
	shieldSectionDescription_04: string;

	// Spider
	spiderName: string;
	spiderDescription: string;
	spiderSectionHeader_01: string;
	spiderSectionDescription_01: string;
	spiderSectionHeader_02: string;
	spiderSectionDescription_02: string;
	spiderSectionHeader_03: string;
	spiderSectionDescription_03: string;
	spiderSectionHeader_04: string;
	spiderSectionDescription_04: string;

	// Staff
	staffName: string;
	staffDescription: string;
	staffSectionHeader_01: string;
	staffSectionDescription_01: string;
	staffSectionHeader_02: string;
	staffSectionDescription_02: string;

	// Planets
	planetsName: string;
	planetsDescription: string;
	planetsSectionHeader_01: string;
	planetsSectionDescription_01: string;
	planetsSectionHeader_02: string;
	planetsSectionDescription_02: string;

	// SymbolQuest
	symbolQuestName: string;
	symbolQuestDescription: string;
	symbolQuestSectionHeader_01: string;
	symbolQuestSectionDescription_01: string;
	symbolQuestSectionHeader_02: string;
	symbolQuestSectionDescription_02: string;

	// TerribleTower
	terribleTowerName: string;
	terribleTowerDescription: string;
	terribleTowerSectionHeaderTrailer: string;
	terribleTowerSectionDescriptionTrailer: string;
	terribleTowerSectionHeader_00: string;
	terribleTowerSectionDescription_00: string;
	terribleTowerSectionHeader_01: string;
	terribleTowerSectionDescription_01: string;
	terribleTowerSectionHeader_02: string;
	terribleTowerSectionDescription_02: string;
	terribleTowerSectionHeader_03: string;
	terribleTowerSectionDescription_03: string;
	terribleTowerSectionHeader_04: string;
	terribleTowerSectionDescription_04: string;
	terribleTowerSectionHeader_05: string;
	terribleTowerSectionDescription_05: string;
	terribleTowerSectionHeader_06: string;
	terribleTowerSectionDescription_06: string;
	terribleTowerSectionHeader_07: string;
	terribleTowerSectionDescription_07: string;
	terribleTowerSectionHeader_08: string;
	terribleTowerSectionDescription_08: string;
	terribleTowerSectionHeader_09: string;
	terribleTowerSectionDescription_09: string;
	terribleTowerSectionHeader_10: string;
	terribleTowerSectionDescription_10: string;
	terribleTowerSectionHeader_11: string;
	terribleTowerSectionDescription_11: string;

	// BachelorThesis
	bachelorThesis: string;
	bachelorName: string;
	bachelorDescription: string;
	bachelorSectionHeader_01: string;
	bachelorSectionDescription_01: string;
	bachelorSectionHeader_02: string;
	bachelorSectionDescription_02: string;
	bachelorSectionHeader_03: string;
	bachelorSectionDescription_03: string;
	bachelorSectionHeader_04: string;
	bachelorSectionDescription_04: string;
	bachelorSectionHeader_05: string;
	bachelorSectionDescription_05: string;
	bachelorSectionHeader_06: string;
	bachelorSectionDescription_06: string;
	bachelorSectionHeader_07: string;
	bachelorSectionDescription_07: string;
	bachelorSectionHeader_08: string;
	bachelorSectionDescription_08: string;
	bachelorSectionHeader_09: string;
	bachelorSectionDescription_09: string;

	// PenAndPaperVr
	penAndPaperVrName: string;
	penAndPaperVrDescription: string;
	penAndPaperVrSectionHeader_01: string;
	penAndPaperVrSectionDescription_01: string;
	penAndPaperVrSectionHeader_02: string;
	penAndPaperVrSectionDescription_02: string;
	penAndPaperVrSectionHeader_03: string;
	penAndPaperVrSectionDescription_03: string;
	penAndPaperVrSectionHeader_04: string;
	penAndPaperVrSectionDescription_04: string;

	// MachineLearning
	machineLearningPaper: string;
	machineLearningName: string;
	machineLearningDescription: string;
	machineLearningSectionHeader_01: string;
	machineLearningSectionDescription_01: string;
	machineLearningSectionHeader_02: string;
	machineLearningSectionDescription_02: string;

	// PimpTheLimb
	pimpTheLimbName: string;
	pimpTheLimbDescription: string;
	pimpTheLimbSectionHeader_01: string;
	pimpTheLimbSectionDescription_01: string;
	pimpTheLimbSectionHeader_02: string;
	pimpTheLimbSectionDescription_02: string;
	pimpTheLimbSectionHeader_03: string;
	pimpTheLimbSectionDescription_03: string;

	// Portals
	portalGunName: string;
	portalGunDescription: string;
	portalGunSectionHeader_01: string;
	portalGunSectionDescription_01: string;

	// Empfängnis
	empfaengnisName: string;
	empfaengnisDescription: string;
	empfaengnisSectionHeader_01: string;
	empfaengnisSectionDescription_01: string;

	// StealthCommander
	stealthCommanderName: string;
	stealthCommanderDescription: string;
	stealthCommanderSectionHeader_01: string;
	stealthCommanderSectionDescription_01: string;

	// GrimRepair
	grimRepairName: string;
	grimRepairDescription: string;
	grimRepairSectionHeader_01: string;
	grimRepairSectionDescription_01: string;

	// GrimRepair
	catastrophicName: string;
	catastrophicDescription: string;
	catastrophicSectionHeader_01: string;
	catastrophicSectionDescription_01: string;

	// Homepage3D
	homepage3DName: string;
	homepage3DDescription: string;
	homepage3DSectionHeader_01: string;
	homepage3DSectionDescription_01: string;
	homepage3DSectionHeader_02: string;
	homepage3DSectionDescription_02: string;
	homepage3DSectionHeader_03: string;
	homepage3DSectionDescription_03: string;
	homepage3DSectionHeader_04: string;
	homepage3DSectionDescription_04: string;
	homepage3DSectionHeader_05: string;
	homepage3DSectionDescription_05: string;

	// KSP

	// Noita

	// RTS

	// ColorSpace


	// Homepage2D
	homepage2D: string;
	homepage2DDescription: string;
	homepage2DSectionHeader_01: string;
	homepage2DSectionDescription_01: string;
	homepage2DSectionHeader_02: string;
	homepage2DSectionDescription_02: string;
	homepage2DSectionHeader_03: string;
	homepage2DSectionDescription_03: string;
	homepage2DSectionHeader_04: string;
	homepage2DSectionDescription_04: string;
	homepage2DSectionHeader_05: string;
	homepage2DSectionDescription_05: string;
	homepage2DSectionHeader_06: string;
	homepage2DSectionDescription_06: string;
	homepage2DSectionHeader_07: string;
	homepage2DSectionDescription_07: string;
	homepage2DSectionHeader_08: string;
	homepage2DSectionDescription_08: string;
	homepage2DSectionHeader_09: string;
	homepage2DSectionDescription_09: string;
	homepage2DSectionHeader_10: string;
	homepage2DSectionDescription_10: string;
	homepage2DSectionHeader_11: string;
	homepage2DSectionDescription_11: string;
	homepage2DSectionHeader_12: string;
	homepage2DSectionDescription_12: string;
	homepage2DSectionHeader_13: string;
	homepage2DSectionDescription_13: string;

	// BoxOfCards
	boxOfCards: string;
	boxOfCardsDescription: string;
	boxOfCardsWebappSectionHeader_01: string;
	boxOfCardsWebappSectionDescription_01: string;
	boxOfCardsWebappSectionHeader_02: string;
	boxOfCardsWebappSectionDescription_02: string;
	boxOfCardsWebappSectionHeader_03: string;
	boxOfCardsWebappSectionDescription_03: string;
	boxOfCardsWebappSectionHeader_04: string;
	boxOfCardsWebappSectionDescription_04: string;
	boxOfCardsWebappSectionHeader_05: string;
	boxOfCardsWebappSectionDescription_05: string;
	boxOfCardsWebappSectionHeader_06: string;
	boxOfCardsWebappSectionDescription_06: string;
	boxOfCardsWebappSectionHeader_07: string;
	boxOfCardsWebappSectionDescription_07: string;
	boxOfCardsArchitectureSectionHeader_00: string;
	boxOfCardsArchitectureSectionDescription_00: string;
	boxOfCardsArchitectureSectionHeader_01: string;
	boxOfCardsArchitectureSectionDescription_01: string;
	boxOfCardsArchitectureSectionHeader_02: string;
	boxOfCardsArchitectureSectionDescription_02: string;
	boxOfCardsArchitectureSectionHeader_03: string;
	boxOfCardsArchitectureSectionDescription_03: string;
	boxOfCardsArchitectureSectionHeader_04: string;
	boxOfCardsArchitectureSectionDescription_04: string;
	boxOfCardsArchitectureSectionHeader_05: string;
	boxOfCardsArchitectureSectionDescription_05: string;
	boxOfCardsArchitectureSectionHeader_06: string;
	boxOfCardsArchitectureSectionDescription_06: string;
	boxOfCardsArchitectureSectionHeader_07: string;
	boxOfCardsArchitectureSectionDescription_07: string;
	boxOfCardsArchitectureSectionHeader_08: string;
	boxOfCardsArchitectureSectionDescription_08: string;
	boxOfCardsTechStackSectionHeader_00: string;
	boxOfCardsTechStackSectionDescription_00: string;
	boxOfCardsTechStackSectionHeader_01: string;
	boxOfCardsTechStackSectionDescription_01: string;
	boxOfCardsTechStackSectionHeader_02: string;
	boxOfCardsTechStackSectionDescription_02: string;
	boxOfCardsTechStackSectionHeader_03: string;
	boxOfCardsTechStackSectionDescription_03: string;
	boxOfCardsTechStackSectionHeader_04: string;
	boxOfCardsTechStackSectionDescription_04: string;
	boxOfCardsTechStackSectionHeader_05: string;
	boxOfCardsTechStackSectionDescription_05: string;
	boxOfCardsDeploymentSectionHeader_00: string;
	boxOfCardsDeploymentSectionDescription_00: string;
	boxOfCardsDeploymentSectionHeader_01: string;
	boxOfCardsDeploymentSectionDescription_01: string;
	boxOfCardsDeploymentSectionHeader_02: string;
	boxOfCardsDeploymentSectionDescription_02: string;
	boxOfCardsTestsSectionHeader_01: string;
	boxOfCardsTestsSectionDescription_01: string;
	boxOfCardsTestsSectionHeader_02: string;
	boxOfCardsTestsSectionDescription_02: string;
	boxOfCardsDesignSectionHeader_00: string;
	boxOfCardsDesignSectionDescription_00: string;
	boxOfCardsDesignSectionHeader_01: string;
	boxOfCardsDesignSectionDescription_01: string;
	boxOfCardsDesignSectionHeader_02: string;
	boxOfCardsDesignSectionDescription_02: string;
	boxOfCardsDesignSectionHeader_03: string;
	boxOfCardsDesignSectionDescription_03: string;
	boxOfCardsDesignSectionHeader_04: string;
	boxOfCardsDesignSectionDescription_04: string;
	boxOfCardsDesignSectionHeader_05: string;
	boxOfCardsDesignSectionDescription_05: string;
	boxOfCardsDesignSectionHeader_06: string;
	boxOfCardsDesignSectionDescription_06: string;
	boxOfCardsDesignSectionHeader_07: string;
	boxOfCardsDesignSectionDescription_07: string;
	boxOfCardsDesignSectionHeader_08: string;
	boxOfCardsDesignSectionDescription_08: string;
	boxOfCardsDesignSectionHeader_09: string;
	boxOfCardsDesignSectionDescription_09: string;
	boxOfCardsDesignSectionHeader_10: string;
	boxOfCardsDesignSectionDescription_10: string;
	boxOfCardsDesignSectionHeader_11: string;
	boxOfCardsDesignSectionDescription_11: string;
	boxOfCardsDesignSectionHeader_12: string;
	boxOfCardsDesignSectionDescription_12: string;
	boxOfCardsDesignSectionHeader_13: string;
	boxOfCardsDesignSectionDescription_13: string;
	boxOfCardsObstaclesSectionHeader_00: string;
	boxOfCardsObstaclesSectionDescription_00: string;
	boxOfCardsObstaclesSectionHeader_01: string;
	boxOfCardsObstaclesSectionDescription_01: string;
	boxOfCardsObstaclesSectionHeader_02: string;
	boxOfCardsObstaclesSectionDescription_02: string;
	boxOfCardsObstaclesSectionHeader_03: string;
	boxOfCardsObstaclesSectionDescription_03: string;
	boxOfCardsObstaclesSectionHeader_04: string;
	boxOfCardsObstaclesSectionDescription_04: string;
	boxOfCardsObstaclesSectionHeader_05: string;
	boxOfCardsObstaclesSectionDescription_05: string;

	// Quotes
	quotes: string[];
	quoterPrefix: string[];
	quoter: string[];
}

const en: IBaseStrings = {
	//App Names
	AppName: "Homepage",
	// Languages
	Language: "Language",
	english: "English",
	german: "German",

	// Pages
	Home: "Home",
	About: "About",
	Contact: "Contact",
	LegalNotice: "Legal Notice",

	// Utility
	loading: "loading",
	more: "more",
	BrightMode: "Bright",
	DarkMode: "Dark",
	SUBMIT: 'SUBMIT',
	Info: "Info",
	Download: 'Download',
	GoogleDrive: 'Google Drive',

	// Legal Notice
	firstName: "Tim",
	secondName: "Beier",
	myCity: "Hamburg",
	email: "contact@timbeier.com",
	addressLine1: "c/o Block Services",
	addressLine2: "Stuttgarter Str. 106",
	addressLine3: "70736 Fellbach",
	addressLine4: "",
	companyName: "",

	// Contact
	contactMeInfo: "Fill up the form and we will respond within 24 hours.",
	FirstName: "First Name",
	EnterFirstName: "Enter first name",
	LastName: "Last Name",
	EnterLastName: "Enter last name",
	Email: "Email",
	EnterEmail: "Enter email",
	Subject: "Subject",
	EnterSubject: "Enter subject",
	Message: "Message",
	TypeYourMessageHere: "Type your message here",
	sendingEmailSuccessful: "sending email successful",

	// About
	Team: "About",
	teamMember01Name: "Tim Beier",
	teamMember01Header01: "Info",
	teamMember01Info01: "Hi!" +
		"\nI am Tim" +
		"\nbased in Hamburg" +
		"\ndevelop\bsince 2012",
	teamMember01Header02: "Experience",
	teamMember01Info02:
		"Software architecture" +
		"\n 3D modeling" +
		"\n Serious games" +
		"\n Mobile games" +
		"\n Web games" +
		"\n Game Design" +
		"\n Web shops" +
		"\n Web 3D" +
		"\n VR",
	teamMember01Header03: "Interests",
	teamMember01Info03:
		"Software architecture" +
		"\nwood working" +
		"\nGame design" +
		"\nboard games" +
		"\npen & paper" +
		"\nscience",
	teamMember01Header04: "Trivia",
	teamMember01Info04:
		"I love meeting new people" +
		"\nJust write me",

	// Projects
	Gameplay: "Gameplay",
	Trailer: "Trailer",

	dinoKingName: "Dino King",
	dinoKingThumbnailDescription: "Throw ham and defend against Dinos!",
	dinoKingSectionDescription_01: "In Dino King\ba Neanderthal fights off dinosaurs.\b" +
	"Dinos are repelled\bby throwing meat hams.\b" +
	"The player paints the trajectory\bof the ham with his finger\band tries not to get eaten\bfor as long as possible.",
	dinoKingSectionHeader_02: "Enemies",
	dinoKingSectionDescription_02: "The never stopping hordes of dinos.\bAren't they cute?\bThey are so cute.\bADMIT IT!",

	skyBlockName: "Sky Block",
	skyBlockDescription: "A low poly landscape in pigart style",
	skyBlockSectionHeader_01: "Description",
	skyBlockSectionDescription_01: "A lovingly 3D Landscape\bin pigart style.",

	corridorName: "3D Corridor",
	corridorDescription: "A high-poly 3D model of a corridor",
	corridorSectionHeader_01: "Description",
	corridorSectionDescription_01: "This rendering shows a 3D,\bhigh-poly model of a\bSci Fi corridor.",
	corridorSectionHeader_02: "Reference",
	corridorSectionDescription_02: "The 3D corridor\babove is based\bon this concept\bby Abraham Wong Shih Yaw.",

	doorName: "3D Sci-Fi Door",
	doorDescription: "A low-poly 3D model of a sci-fi door",
	doorSectionHeader_01: "Description",
	doorSectionDescription_01: "This rendering shows\ba low-poly 3D model of a sci fi door." +
		"\nIt consists of a frame with\bfour moving pieces totaling in 300 triangles." +
		"\nThe following texture maps are used:\bdiffuse, specular, normal",

	// Shield
	shieldName: "3D Shield",
	shieldDescription: "A low poly 3D model of a shield",
	shieldSectionHeader_01: "Description",
	shieldSectionDescription_01: "This rendering shows a\blow-poly 3D model\bof a shield with a skull." +
		"\nThe image has a partial\boverlay of the 3D mesh topology.",
	shieldSectionHeader_02: "Back",
	shieldSectionDescription_02: "The reference does not have a back\bso I came up with one.",
	shieldSectionHeader_03: "Side",
	shieldSectionDescription_03: "This picture shows the chocolate side.",
	shieldSectionHeader_04: "Reference",
	shieldSectionDescription_04: "The 3D shield shown above is based on this concept by Artyom Vlaskin.",

	// spider
	spiderName: "3D Mechanical Spider",
	spiderDescription: "A low poly 3D model of a mechanical spider",
	spiderSectionHeader_01: "Front",
	spiderSectionDescription_01: "This rendering shows a\blow-poly 3D model\of a mechanical spider." +
		"\nThis model is not based on any reference\band came from my imagination." +
		"\nThe spider has a motor block\bThe legs are hydraulically\bmovable and animatable.",
	spiderSectionHeader_02: "Back",
	spiderSectionDescription_02: "In the rear view\bthe gear transmission is visible." +
		"\nIt also shows that the engine block\bis suspended from chains.",
	spiderSectionHeader_03: "Front Topology",
	spiderSectionDescription_03: "The front has an overlay\bof the 3D mesh topology.",
	spiderSectionHeader_04: "Back Topology",
	spiderSectionDescription_04: "The back has an overlay\bof the 3D mesh topology.",

	// Staff
	staffName: "3D Rod",
	staffDescription: "A low poly 3D model of a rod",
	staffSectionHeader_01: "Description",
	staffSectionDescription_01: "This rendering shows a\blow-poly 3D model of a rod." +
		"\nThe image has a partial\boverlay of the 3D mesh topology." +
		"\nThe following texture maps are used:\bDiffuse, Normal",
	staffSectionHeader_02: "Reference",
	staffSectionDescription_02: "The 3D rod shown above is based\bon this concept by Roman Guro.",

	// Planets
	planetsName: "Planets",
	planetsDescription: "Some low-poly 3D planets",
	planetsSectionHeader_01: "One planet",
	planetsSectionDescription_01: "Just a rendering of a low-poly 3D planet.",
	planetsSectionHeader_02: "More planets",
	planetsSectionDescription_02: "Just some more planets",

	// SymbolQuest
	symbolQuestName: "Symbol Quest: Riddle Challenge",
	symbolQuestDescription: "Symbol Quest is a 2D Puzzle Game from 2015",
	symbolQuestSectionHeader_01: "Description",
	symbolQuestSectionDescription_01: "Symbol Quest: Riddle Challenge was\breleased in 2015 by Red Orb Games\bon Android and iOs.",
	symbolQuestSectionHeader_02: "Gameplay",
	symbolQuestSectionDescription_02: "The player draws lines across the screen\bto reveal images." +
		"\nWhere lines overlap with the hidden subject,\bthe subject is revealed." +
		"\nThe player has to guess the motif\bwith a limited number of lines.",

	// TerribleTower
	terribleTowerName: "Terrible Tower",
	terribleTowerDescription: "Terrible Tower is a 2D Roguelite Puzzle Platformer from 2014",
	terribleTowerSectionHeaderTrailer: "Trailer",
	terribleTowerSectionDescriptionTrailer: "The release Trailer.",
	terribleTowerSectionHeader_00: "Description",
	terribleTowerSectionDescription_00: "Terrible Tower was released\bin 2015 by Red Orb Games\bon Android and iOs.",
	terribleTowerSectionHeader_01: "Gameplay",
	terribleTowerSectionDescription_01: "The player must climb a magic tower." +
		"\nTo do this, he must jump through the bottom\bscreen to reappear at the top\bscreen.\bMagic!" +
		"\nThe tower consists of 10 ages\bwith different dangers\band starts in the Stone Age.",
	terribleTowerSectionHeader_02: "Runic Ages",
	terribleTowerSectionDescription_02: "The Runic Age glows magically\band contains dangerous fire\bas well as magical projectiles.",
	terribleTowerSectionHeader_03: "Age of Rome.",
	terribleTowerSectionDescription_03: "In the Age of Rome, ballistae,\bmorning stars and drop stones\bmake the way difficult.",
	terribleTowerSectionHeader_04: "Middle Ages",
	terribleTowerSectionDescription_04: "In the Middle Ages,\bcountless axes swing.\bDuck!",
	terribleTowerSectionHeader_05: "Industrial Age",
	terribleTowerSectionDescription_05: "The Industrial Age contains\bcircular saws and hydraulic presses.",
	terribleTowerSectionHeader_06: "Art-deco age",
	terribleTowerSectionDescription_06: "Now circular saws are even flying\band rotating flamethrowers\bas well as automatic guns are everywhere.",
	terribleTowerSectionHeader_07: "Atomic Age",
	terribleTowerSectionDescription_07: "The Atomic Age is the time\bof free radicals,\btoxic barrels and nuclear missiles.",
	terribleTowerSectionHeader_08: "Matrix",
	terribleTowerSectionDescription_08: "In the Matrix,\blasers regularly block the way,\bbombs block the way,\band everything is constantly moving!",
	terribleTowerSectionHeader_09: "Scifi Age",
	terribleTowerSectionDescription_09: "Rotating laser turrets\beverywhere and everything is\bmoving all the time!",
	terribleTowerSectionHeader_10: "End Times",
	terribleTowerSectionDescription_10: "In the End Time, traps from\ball previous ages are present.\bCompletely random!",
	terribleTowerSectionHeader_11: "MainMenu",
	terribleTowerSectionDescription_11: "The Main Menu allows you to\bbuy upgrades, skins and view achievements\band high scores.",

	// Bachelor
	bachelorThesis: "Bachelor Thesis",
	bachelorName: "Navigation in non-euclidean VR",
	bachelorDescription: "The bachelor thesis for my studying of applied informatics",
	bachelorSectionHeader_01: "Description",
	bachelorSectionDescription_01: "This is the thesis for\bmy degree in applied computer science." +
		"\nIt was investigated whether non-Euclidean\bVR environments are suitable\to reduce navigation times in unknown\benvironments.",
	bachelorSectionHeader_02: "Experiment",
	bachelorSectionDescription_02: "20 subjects were given the task\bof finding 20 landmarks in a VR environment." +
		"\nThere was an euclidean\band a non-euclidean\bVR environment and both were\bconstructed in the same way." +
		"\nThe non-Euclidean environment\bhad shorter walking distances through\bcurvatures of space." +
		"\nProbands in the non-euclidean\bVR environment had no problems\bwith spatial overlaps\band were 40% faster on average.",
	bachelorSectionHeader_03: "Hyperbolic space curvature",
	bachelorSectionDescription_03: "Figure a) shows 5 interconnected rooms.\bThe doors are marked with numbers." +
		"\nSuch a space is not possible in 3-dimensional\bspace, since spaces\bwould overlap." +
		"\nIt is only made possible by a hyperbolic\bspace curvature as in b).",
	bachelorSectionHeader_04: "Elliptic space curvature",
	bachelorSectionDescription_04: "The spaces in a) can only be connected by an\belliptic space curvature as shown in b)." +
		"\nThis shortens the distance\bbetween the spaces.",
	bachelorSectionHeader_05: "Distance saving",
	bachelorSectionDescription_05: "This illustration shows how a\bdistance between two rooms can be shortened\bif they are allowed to overlap\b.",
	bachelorSectionHeader_06: "Distance saving from ego perspective",
	bachelorSectionDescription_06: "In the figure, the subject is looking at two doors." +
		"\nThe left one leads into a hallway\band the right one into a garage.\bIt is already obvious from this detail,\bthat such a room layout should be impossible." +
		"\nIn this case, it\bis made possible by portals.",
	bachelorSectionHeader_07: "Euclidean VR environment",
	bachelorSectionDescription_07: "In this figure, the\beuclidean VR environment is shown\bfrom a bird's eye view.",
	bachelorSectionHeader_08: "Non-euclidean VR environment",
	bachelorSectionDescription_08: "In this figure, the\bnon-euclidean VR environment\bis shown from the bird's eye view is shown." +
		"\nThe spaces are separated from each other\bso that they do not overlap\band remain connected to each other by portals 'P'." +
		"\nThe colored lines indicate\bthe paths between the rooms.",
	bachelorSectionHeader_09: "Component Diagram",
	bachelorSectionDescription_09: "In this diagram,\bthe components are shown,\bthat make the experiment possible.\b" +
		"\nBefore I explain the diagram here\bI refer directly to my bachelor thesis\bwhich can be downloaded below.",

	// PenAndPaperVr
	penAndPaperVrName: "Pen and Paper VR",
	penAndPaperVrDescription: "A university project on the applicability of pen and paper in VR",
	penAndPaperVrSectionHeader_01: "Description",
	penAndPaperVrSectionDescription_01: "Players can create units\band place them on a game grid." +
		"\nThe playing field can be\bmoved using arrows on the table.",
	penAndPaperVrSectionHeader_02: "Character Sheets",
	penAndPaperVrSectionDescription_02: "Units have editable\bcharacter sheets." +
		"\nA virtual keyboard is\bused for editing." +
		"\nThe character sheets\bcan be saved and loaded.",
	penAndPaperVrSectionHeader_03: "Level",
	penAndPaperVrSectionDescription_03: "All sorts of junk can be placed\bon the playing field." +
	"\nElements outside the visible grid\bare automatically hidden.",
	penAndPaperVrSectionHeader_04: "Tracked Cube",
	penAndPaperVrSectionDescription_04: "On the premise we had\baccess to tracking cameras." +
		"\nThis cube could be used\bto roll dice in real time\bin the VR environment.",

	// MachineLearning
	machineLearningPaper: "AgentsPaper",
	machineLearningName: "Machine Learning",
	machineLearningDescription: "Agents are assigned random rulesets and teams and fight for their survival.",
	machineLearningSectionHeader_01: "Description",
	machineLearningSectionDescription_01: "This is a snippet from\bone of my university projects on\bcomplex adaptive systems." +
		"\nAgents fight in teams\bfor resources and their survival.",
	machineLearningSectionHeader_02: "Rules",
	machineLearningSectionDescription_02: "Each agent has a random set of rules." +
		"\nEach rule and turn has a cost." +
		"\nThese rules include f.e.\bfleeing when outnumbered\bor not walking into obstacles." +
		"\nThis picture shows how rules\bare handled from top to bottom." +
		"\nThe course was held in german\bso the following paper is as well.",

	// PimpTheLimb
	pimpTheLimbName: "Cannon Jump",
	pimpTheLimbDescription: "A prototype in which you are a cannon.",
	pimpTheLimbSectionHeader_01: "Description",
	pimpTheLimbSectionDescription_01: "This video shows the mechanic." +
		"\nThe player can slightly\broll left and right." +
		"\nShooting has a knockback\band can be used to jump." +
		"\nRotating reloads ammunition." +
		"\nThe ammunition is\bdisplayed on the cannon.",
	pimpTheLimbSectionHeader_02: "Version 1",
	pimpTheLimbSectionDescription_02: "This is the first prototyped version." +
		"\nThis idea is based on Mount Your Friend\bbut controlling four cannons was impractical.",
	pimpTheLimbSectionHeader_03: "Version 2",
	pimpTheLimbSectionDescription_03: "Due to simplification\bonly one cannon is used." +
		"\nBut the character can now\broll slightly to either side." +
		"\nThis idea was discontinued\bas level design is a nightmare.",

	// Portals
	portalGunName: "Portal Gun",
	portalGunDescription: "This is a prototype of a self made Portal Gun",
	portalGunSectionHeader_01: "Description",
	portalGunSectionDescription_01: "The player can shoot two projectiles\bto create linked Portals." +
		"\nOn Collision the projectiles find\bthe best positioning and\borientation for Portals." +
		"\nPortals allow for seamless transition\bof the player and props." +
		"\nThe only thing breaking the immersion\bare the shades due to lighting." +
		"\nThis was created using multiple cameras\band toggling collision layers.",

	// Empfängnis
	empfaengnisName: "Empfängnis",
	empfaengnisDescription: "A fantasy novel i wrote with 2 friends back in school",
	empfaengnisSectionHeader_01: "Empfängnis",
	empfaengnisSectionDescription_01:  "This fantasy novel was written\bpage by page while taking turns." +
	"\nI passed my page to Patrick,\bPatrick to Kjeld\band Kjeld to me." +
	"\nThis resulted in everybody\blacking essential information\band hilarious plot twists." +
	"\nIf you do not take life\btoo seriously give it a read." +
	"\nSidenote: I was overruled on the name.",

	// StealthCommander
	stealthCommanderName: "Move units using curves",
	stealthCommanderDescription: "A prototype for a curve based movement system using curve handles",
	stealthCommanderSectionHeader_01: "Description",
	stealthCommanderSectionDescription_01: "Units can be selected and commanded." +
		"\nMoving Units have their path displayed\band each trajectory has a handle." +
		"\nMoving the handle will alter the path,\bthus creating a curve." +
		"\nThe maximum curvature is proportional\bthe length of the path.",

	// GrimRepair
	grimRepairName: "Grim Repair",
	grimRepairDescription: "Global Game Jam of 2020 with the topic 'Repair'",
	grimRepairSectionHeader_01: "Description",
	grimRepairSectionDescription_01: "In Grim Repair the Grim Reaper\bhas killed all craftsman." +
		"\nNow it is your task to build machines from parts." +
		"\nThose machines then have to attack\bthe Grim Reaper to stop him." +
		"\nIt turns out that the whole game was built\bon top of a even bigger Reaper.",

	// Catastrophic
	catastrophicName: "Catastrophic",
	catastrophicDescription: "Global Game Jam of 2019 with the topic 'Home'",
	catastrophicSectionHeader_01: "Description",
	catastrophicSectionDescription_01: "In Catastrophic a cat\bis demolishing your home." +
		"\nYou are a robot with magnetic powers\band the task to repair everything." +
		"\nCollect and shoot screwdrivers\bto repair things." +
		"\nBe aware that repairing things may attract\bmore cats, screwdrivers, and weirdness." +
		"\nI just love fluid simulations.",

	// Homepage3D
	homepage3DName: "Made of Cubes",
	homepage3DDescription: "My previous homepage which is 100% made of 3D cubes",
	homepage3DSectionHeader_01: "Description",
	homepage3DSectionDescription_01: "I can't remember how I got the\babsurd idea to build a homepage 100% in 3D." +
		"\nbut hey it looks cool when cubes fly around." +
		"\nFunfact 1: All cubes used on the homepage\bare a clone of the shown loading symbol."+
		"\nFunfact 2: It needs a dedicated GPU to run smoothly\band the application can't set it itself.",
	homepage3DSectionHeader_02: "Custom Font",
	homepage3DSectionDescription_02: "I had the grandiose idea to create\ball the text from 3D cubes too." +
		"\nSo why not finish the wooden path\band build your own low-poly font!",
	homepage3DSectionHeader_03: "Animate cubes",
	homepage3DSectionDescription_03: "It's also much cooler then when\bcubes fly around to display text." +
		"\nIt turns out that thousands of animations (tweens)\bfor position, rotation, scaling and color\bbecome computationally intensive after all."+
		"\nTherefore, all animations on\bthe page without exception are\balready computed when called.",
	homepage3DSectionHeader_04: "Dice form contact page",
	homepage3DSectionDescription_04: "The cubes are now flown\bto their positions and rotated." +
		"\nTo make Interactions possible ray casts\bof the mouse are used to color words." +
		"\nNon-existent cubes for longer texts\bare generated automatically.",
	homepage3DSectionHeader_05: "Portfolio",
	homepage3DSectionDescription_05: "Lastly, my portfolio must\balso be displayed." +
		"\nFor this, they will be displayed\bas images on the same cubes." +
		"\nWhen changing pages, the images\bare exchanged exactly when the objects\bare orthogonal to the camera." +
		"\nUnfortunately, these were not 90 degree angles\bbecause the camera is perspective." +
		"\nI also included videos\bin this 3D space on the cubes." +
		"\nIt was a nightmare because additional\b2D cameras are needed." +
		"\nEspecially when zooming.",

	// Homepage2D
	homepage2D: "Homepage 2D",
	homepage2DDescription: "Development of this Homepage",
	homepage2DSectionHeader_01: "Homepage 2D",
	homepage2DSectionDescription_01: "Describing my Homepage\bon my Hompage. Fun!",
	homepage2DSectionHeader_02: "Rendering",
	homepage2DSectionDescription_02: "Using Material-UI to style elements\band apply themes." +
		"\nI chose React as i am slowly\bbuilding up my reusable components." +
		"\nRedux might be overkill\bfor such a small project\bbut i use this as template\bfor other projects.",
	homepage2DSectionHeader_03: "Themes",
	homepage2DSectionDescription_03: "Material-UI allows for\beasy usage for themes." +
		"\nThus allowing for a bright\band a dark mode <3",
	homepage2DSectionHeader_04: "Styling",
	homepage2DSectionDescription_04: "Material-UI also gives\bsome handy styling.",
	homepage2DSectionHeader_05: "Redux Store",
	homepage2DSectionDescription_05: "The redux store gives us\bglobal state management." +
		"\nF.e. to display Toasts using\bthe AlertState or updating\bthe language selection\busing the languageState.",
	homepage2DSectionHeader_06: "Example: Contact Page",
	homepage2DSectionDescription_06: "Pages are so compact\busing those libraries." +
		"\nThe component contains type\bdefinitions for props passed\binto the component." +
		"\nAn internal state and\bfunctions to manage input." +
		"\nAnother function to send\ban email on submit." +
		"\nAnd lastly the Global State\bfor f.e. Alerts & Styles\bis mapped onto the component.",
	homepage2DSectionHeader_07: "Compilation",
	homepage2DSectionDescription_07: "To avoid the Javascript hell\bTypescript is used. " +
		"\nTypes are love." +
		"\nWebpack compiles\bthe code into small bundle." +
		"\nDocker because Docker! Hurray!",
	homepage2DSectionHeader_08: "Project Config Example: Portals",
	homepage2DSectionDescription_08: "Every Project of this homepage\bis defined in a config." +
		"\nThis makes it easier to\badd new projects over time." +
		"\nThat block alone creates\ba card on the main page\band also a complete page\bin a sub route." +
		"\nThis page is such a config too.",
	homepage2DSectionHeader_09: "Project Config Types",
	homepage2DSectionDescription_09: "A Project Page consists of all of these type definitions." +
		"\nSeveral are optional like\bvideos, images, downloads, etc.",
	homepage2DSectionHeader_10: "Docker File",
	homepage2DSectionDescription_10: "There is not much to be said here." +
		"\nInstall packages, create a build\band that build will contain\ba public index.html",
	homepage2DSectionHeader_11: "Deployment",
	homepage2DSectionDescription_11: "Code is on Github." +
		"\nDynadot is my domain provider." +
		"\nNetlify is used for\bautomatic deployment & Hosting.",
	homepage2DSectionHeader_12: "Utility Libraries",
	homepage2DSectionDescription_12: "Imagekit.io allows\buploading of images." +
		"\nThis way this homepage is\bbeing loaded faster as images\bare only downloaded on demand." +
		"\nand are automatically resized\bto the required size." +
		"\nEmailJs allows for very\beasy sending of emails.",
	homepage2DSectionHeader_13: "ImageKit Io",
	homepage2DSectionDescription_13: "This is a Screenshot of\bmy Imagekit.io root containing\ball images used for this hompage.",

	// BoxOfCards
	boxOfCards: "Box of Cards",
	boxOfCardsDescription:
		"A customizable card game to tell stories with friends and an overkill tech stack",
	boxOfCardsWebappSectionHeader_01: "Game Design",
	boxOfCardsWebappSectionDescription_01:
		"In Box of Cards players\bare handed Answers which can be\binserted into Sentences.",
	boxOfCardsWebappSectionHeader_02: "Adaptable Cards",
	boxOfCardsWebappSectionDescription_02:
		"Cards can have dropdowns\bto make cards more adaptable\bto the sentence.",
	boxOfCardsWebappSectionHeader_03: "Choose Decks",
	boxOfCardsWebappSectionDescription_03:
		"The Host can decide to use\bone or combine multiple decks\bfor the new session.",
	boxOfCardsWebappSectionHeader_04: "Create Decks",
	boxOfCardsWebappSectionDescription_04:
		"The Player can create his own decks\band test them directly." +
		"\nSentences contain Answer\bplaceholders using '_'." +
		"\nAnswers can have dropdown values\bby adding them comma separated\binto square brackets [one, two].",
	boxOfCardsWebappSectionHeader_05: "Multiplayer Lobby",
	boxOfCardsWebappSectionDescription_05:
		"Public games can be joined\band seen by anyone. " +
		"\nI admit i am lazy when\bchoosing names for testing.",
	boxOfCardsWebappSectionHeader_06: "Game Lobby",
	boxOfCardsWebappSectionDescription_06:
		"The host may set the Game settings\bin the Game Lobby. " +
		"\nHe also has to approve players\bwanting to join." +
		"\nOnce all players are ready the game may begin.",
	boxOfCardsWebappSectionHeader_07: "Game",
	boxOfCardsWebappSectionDescription_07:
		"Based on the settings\ba sentence is chosen and Players\bsubmit their Answers for voting." +
		"\nIn this case they may submit\bup to two Answers." +
		"\nThere already played a round\band thus have a completed\bCard in their history at the top.",
	boxOfCardsArchitectureSectionHeader_00: "Overengineered to the limit",
	boxOfCardsArchitectureSectionDescription_00:
		"I started this project\bas i wanted to try out\blots of new technologies." +
		"\nAll of this could have been done\bin an express server on a Raspberry Pi." +
		"\nBut a wise person once said:\bGive a man a fish\band he eats for a day." +
		"\nGive a man a Fishing-Boat-Industry\band he will never see\ba fish again.",
	boxOfCardsArchitectureSectionHeader_01: "Components",
	boxOfCardsArchitectureSectionDescription_01:
		"Now what did i choose\bif a node.js server\bwould have been sufficient?" +
		"\nWell what update\ba kubernetes cluster\bcontaining microservices!" +
		"\nAnd what do they do?\bI will tell ya!",
	boxOfCardsArchitectureSectionHeader_02: "Web Client",
	boxOfCardsArchitectureSectionDescription_02:
		"The Web-Client is being compiled\band then served\bby an express Server." +
		"\nThe express Server is run\binside a docker container." +
		"\nAnd the docker container\bis deployed using a\bkubernetes deployment." +
		"\nLastly a domain points towards\ba load-balancer service which points\bto the express server.",
	boxOfCardsArchitectureSectionHeader_03: "Backend",
	boxOfCardsArchitectureSectionDescription_03:
		"Three services which handle\bthe games multiplayer logic." +
		"\nThe MultiplayerLobby serves\bavailable GameLobbies." +
		"\nThe GameLobby handles, well....\bthe Games lobby." +
		"\nOnce the host clicks\bthe start button inside the GameLobby\ball players migrate onto a Game Server.",
	boxOfCardsArchitectureSectionHeader_04: "Redis Cluster",
	boxOfCardsArchitectureSectionDescription_04:
		"Redis is being used\bto cache data which is required\bacross those backend services." +
		"\nFor example it stores\bwhich player is assigned\bto which Game or GameLobby." +
		"\nThis avoids players being\bin multiple games or\bbeing unable to reconnect.",
	boxOfCardsArchitectureSectionHeader_05: "RabbitMq Broker",
	boxOfCardsArchitectureSectionDescription_05:
		"The broker allows us\bto loosely couple client sessions\bwith backend services\band thus enable scaling." +
		"\nWhen a client connects\bvia the domain he has to use\bwebsockets (WebStomp protocol)\bbecause HTML~~~. " +
		"\nThe backend services\bare express servers\band thus use the AMQP protocol.",
	boxOfCardsArchitectureSectionHeader_06: "Google Cloud",
	boxOfCardsArchitectureSectionDescription_06:
		"Why on earth do i also use\ba google cloud additionally\bto kubernetes?" +
		"\nWell my initial plan was to use\bfirebase to manage gamestate\bbut my calculations resulted in\b... bankruptcy." +
		"\nI still use google cloud functions\bto manage authentications\band store decks though." +
		"\nNo need to implement\bauthentication by myself." +
		"\nCloud Functions are realllly fun.",
	boxOfCardsArchitectureSectionHeader_07: "My Cluster Overview",
	boxOfCardsArchitectureSectionDescription_07:
		"These are all the services\band config files of my running cluster,\bthough some persistent volumes\bare not being displayed." +
		"\nThere is only one pod\bof each service\bas i am running on the cheapest\bNode plan available." +
		"\nWhy the restarts?\bThe Broker takes some time\bto get up and i have yet\bto add readiness probes.",
	boxOfCardsArchitectureSectionHeader_08: "Digital Ocean Dashboard",
	boxOfCardsArchitectureSectionDescription_08:
		"Nothing fancy, just my cluster\band the two load-balancers\bpointing at it." +
		"\nOne for fetching the web client\band one for accessing the broker\bfor multiplayer interaction." +
		"\nWhy can't this be one loadbalancer?\bWell i tried quite some stuff\bbut due to port requirements\bacross services it won't work.",
	boxOfCardsTechStackSectionHeader_00: "Tech Stacks",
	boxOfCardsTechStackSectionDescription_00: "What libraries did i use?\bDid i use libraries?\bLet's find out!",
	boxOfCardsTechStackSectionHeader_01: "Web App",
	boxOfCardsTechStackSectionDescription_01:
		"Most of the content is in the Client,\bthus i use some frontend libraries\bto make it look nice." +
		"\nMaterial Ui as a solid design base,\bReact for reusable components\band Redux to manage states globally." +
		"\nTypescript to avoid the javascript hell,\bwebpack to make the build\bmore compact and docker to deploy." +
		"\nImagekit.io is compatible with React\band allows the outsourcing\bof images into the cloud,\bthus reducing load time." +
		"\nThe server is just one file\bto serve it all\band  proxy some requests\bto the Google Cloud.",
	boxOfCardsTechStackSectionHeader_02: "Backend Services",
	boxOfCardsTechStackSectionDescription_02:
		"These services all share\bthe same core components." +
		"\nThe green boxes are\bnpm packages that github worfklows\bcreate from my other repositories." +
		"\nOther than that i use\bjest for unit testing." +
		"\nThe broker package lets those services\btalk to the RabbitMq broker." +
		"\nTypes allow for consistent\bsending & receiving of data." +
		"\nTestData & MockProvider\bare dev dependencies to avoid\bredundancy in unit tests." +
		"\nThen there is CheckAlive\bwhich just checks whether a player\bis still connected." +
		"\nAnd lastly my wrapper\bfor Redis interaction GameRedis.",
	boxOfCardsTechStackSectionHeader_03: "Backend Packages",
	boxOfCardsTechStackSectionDescription_03:
		"This is the tech stack of my packages." +
		"\nThe Broker supports RabbitMq\bwith stomp.js via websockets\band AMQP via CloudAMQP." +
		"\nThe GameRedis uses\bmy more generic RedisClient package,\bwhich uses packages for\bredis cluster interaction." +
		"\nAll those other packages\bare just the core.",
	boxOfCardsTechStackSectionHeader_04: "Google Cloud Functions",
	boxOfCardsTechStackSectionDescription_04:
		"The Cloud Functions run on node.js\band use a firebase package\bto interact with the firestore." +
		"\nThe firestore stores decks\band verification links as well as\bsome basic account data.",
	boxOfCardsTechStackSectionHeader_05: "RabbitMq",
	boxOfCardsTechStackSectionDescription_05:
		"RabbitMq is configured\busing only kubernetes config files\band has plugins so that\bserver and client\bcan talk with the broker.",
	boxOfCardsDeploymentSectionHeader_00: "Deployment",
	boxOfCardsDeploymentSectionDescription_00: "Well now we got all this kababl\bbut how do we form\ba cloud out of it?" +
		"\nDevOps & Pipelines to the rescue.",
	boxOfCardsDeploymentSectionHeader_01: "Github Workflow",
	boxOfCardsDeploymentSectionDescription_01:
		"Just store a git workflow file\bin the repository and bam\bwe can define jobs." +
		"\nJobs can be dependent on one another,\bcheck out commits and even\binstall plugins themselves." +
		"\nThis awesome magic. I love them!" +
		"\nI use a job to get the package versions\band check whether they have updated\bin the current commit." +
		"\nIf so, rebuild docker images." +
		"\nIf anything got rebuilt,\bdelete stuff from my digital ocean cluster\band deploy it anew." +
		"\nYes my workflow talks\bwith digital ocean!" +
		"\nAnd all of this in just\b~300 lines of config (without empty lines).",
	boxOfCardsDeploymentSectionHeader_02: "The Toolbox",
	boxOfCardsDeploymentSectionDescription_02:
		"A good friend gave me\ba nice template for a shell-script\band it made testing things\bso much easier." +
		"\nI extended it over time\band it allows me to switch\bbetween my local cluster\band the digital ocean cluster." +
		"\nIf i do not want to use the github action\bto redeploy a pod\bi can just use this to redeploy all,\bor just a single service." +
		"\nI think Helm does something similar\bso that will be\bthe next thing to check out." +
		"\nI also deployed a cluster\busing Terraform but found it\bto be overkill for this project." +
		"\nYes it seems i have a limit for overengineering",
	boxOfCardsTestsSectionHeader_01: "Unit Tests",
	boxOfCardsTestsSectionDescription_01:
		"Green numbers on a Unit Test\bprovide one of the best feelings." +
		"\nThere are over 400 unit tests\bin this project and some dozen\bintegration tests." +
		"\nI still have some logical branches\bto be covered and the web client\bhas no unit tests whatsoever.",
	boxOfCardsTestsSectionHeader_02: "Unit Test Output",
	boxOfCardsTestsSectionDescription_02:
		"This is the output\bof my TimeoutHandler package." +
		"\nYes i am showing this minor package\bbecause my bigger packages\bare not that covered but psssht!",
	boxOfCardsDesignSectionHeader_00: "Origins",
	boxOfCardsDesignSectionDescription_00:
		"In the following sections\bi will post parts of the plans\band designs i made on the way." +
		"\nI remember my professor\bon Software Design repeating:\b'Planning is essential. Plans are useless.'",
	boxOfCardsDesignSectionHeader_01: "1. Game Board",
	boxOfCardsDesignSectionDescription_01:
		"I concept everything in draw.io\bas i found that tool\bto be the least annoying." +
		"\nThe bottom left shows\bmy first idea for a game board." +
		"\nIt was not flexible enough\bfor resolutions and game options.",
	boxOfCardsDesignSectionHeader_02: "2. Game Board",
	boxOfCardsDesignSectionDescription_02:
		"\nThis design is more flexible\band rounding makes it seem easier",
	boxOfCardsDesignSectionHeader_03:  "Page Transitions",
	boxOfCardsDesignSectionDescription_03:
		"\nWith everything in one document\bi can also concept page transitions.",
	boxOfCardsDesignSectionHeader_04: "Gameloop",
	boxOfCardsDesignSectionDescription_04:
		"It ain't pretty but it was sufficient\bto create the game-states." +
		"\nOn game start the game-states\bare linked based on the game options\bin the game lobby." +
		"\nF.e. for selecting a sentence\bthere is a GS1GetRandomSentenceGameState\band a GS1SelectingSentenceGameState." +
		"\nBoth share the base class\bGetSentenceGameStateBase to avoid redundancy.",
	boxOfCardsDesignSectionHeader_05: "UML client board",
	boxOfCardsDesignSectionDescription_05:
		"To get a rough overview\bof what components will be required\bi like to start out\bcreating class diagrams." +
		"\nIt feels like solving\ba Sodoku except it really is exciting.",
	boxOfCardsDesignSectionHeader_06: "Sequencing",
	boxOfCardsDesignSectionDescription_06:
		"The web client and services\bcommunicate via the Broker using Queues\bto subscribe on and publish to." +
		"\nSeveral messages have to be exchanged\busing different queues\bto f.e. create a game lobby.",
	boxOfCardsDesignSectionHeader_07: "First Page",
	boxOfCardsDesignSectionDescription_07:
		"This was my first page\brunning in localhost." +
		"\nHilarious what it once looked like.",
	boxOfCardsDesignSectionHeader_08: "Maybe use a scribble design?",
	boxOfCardsDesignSectionDescription_08:
		"I tried really hard\bto make a hand drawn look possible\bbut giving good usage feedback\bwas just too hard." +
		"\nThere seems to be a reason\bthat web pages are always so clean.",
	boxOfCardsDesignSectionHeader_09: "Concepting Designs",
	boxOfCardsDesignSectionDescription_09:
		"In Affinity Designer i concepted\bseveral design ideas." +
		"\nEven though some looked really nice\bit was rough getting all\bcomponents fitting one another.",
	boxOfCardsDesignSectionHeader_10: "Designing Logos",
	boxOfCardsDesignSectionDescription_10:
		"This was my second approach at a logo." +
		"\nI am too ashamed to show\bmy first version\band even these hands are...\bstolen from the wilds of the internet." +
		"\nI really like it\bbut it was impossible to make\ba good looking icon out of it.",
	boxOfCardsDesignSectionHeader_11: "Designing the logo",
	boxOfCardsDesignSectionDescription_11:
		"I started watching\blogo concepting videos on side\band found one using a box\bconsisting of letters." +
		"\nI was able to adapt\bit using the letters B, O and C\band add in some cards." +
		"\nReally happy with the result.\bThings started coming together.",
	boxOfCardsDesignSectionHeader_12: "Designing the Game Board",
	boxOfCardsDesignSectionDescription_12:
		"I started focussing on\bmaking the ui responsive. " +
		"\nA fixed height History at the top,\ba Hand at the bottom,\bSentence & Info Left\band a body to fill up\bthe remaining space." +
		"\nI made the optional stuff\blike Chat, Player-List\band Options hide-able." +
		"\nIt turned out that those\bcomponent headers were a nightmare\bin responsive design\bso i moved them into\bthe components to only\bbe displayed when\bthey are empty.",
	boxOfCardsDesignSectionHeader_13: "Testing Fonts",
	boxOfCardsDesignSectionDescription_13: "I had some friends rate\bfonts for this application." +
		"\nThey chose 4 and 11\band so i proceeded with 4\bas i liked it most of them.",
	boxOfCardsObstaclesSectionHeader_00: "How many obstacles could there be?",
	boxOfCardsObstaclesSectionDescription_00:
		"Well i never counted\bbut lets say curses existed!" +
		"\nSpecial mentions to:\bscrollbars,\btable implementations,\bchecking negated enums,\bcors,\bcaching,\bcaching,\b" +
		"git being case insensitive,\bGoogle Agones,\bpackage dependency conflicts,\breact-router v5->v6, " +
		"\nweird behaving cluster regions\band github telling me i reached\b9500 of my 2000 build minutes\bwith a 56$ balance on my 0$ limit." +
		"\nIt was their bug though.",
	boxOfCardsObstaclesSectionHeader_01: "Umlauts!",
	boxOfCardsObstaclesSectionDescription_01:
		"In the german language\bthere are umlauts (ä,ö, etc.)\band it turns out the font\bi chose did not have those so...." +
		"\nI added them myself!\b" +
		"\nI guess that is fine\bas i bought this font and otherwise:\bpsssht.",
	boxOfCardsObstaclesSectionHeader_02: "Font Borders",
	boxOfCardsObstaclesSectionDescription_02:
		"The font also had some lines repeated\bfor... reasons i guess." +
		"\nIt was fine until i added\ba border which turned out weird." +
		"\nSo i edited those out." +
		"\nI knew the font editor by now.",
	boxOfCardsObstaclesSectionHeader_03: "Display Settings",
	boxOfCardsObstaclesSectionDescription_03:
		"What is this you ask?\bModern art?\bYes probably." +
		"\nIt is also the color difference\bof both my monitors\band i was incapable to\bget them any closer." +
		"\nI decided to resolve\bthis issue by not caring at all\band moving on.",
	boxOfCardsObstaclesSectionHeader_04: "Debugging Unit Tests",
	boxOfCardsObstaclesSectionDescription_04:
		"Well some error messages\bare at first confusing\band then they get weird." +
		"\nI use Typescript so the types\bare set but when passing data along\ba number can still be a string\beven though the compiler thinks\bit is a number." +
		"\nNananananananana Javascriiiipt",
	boxOfCardsObstaclesSectionHeader_05: "How long did this journey take?",
	boxOfCardsObstaclesSectionDescription_05:
		"Well i don't know\bbut in all repositories together\bthere are 1000 + commits." +
		"\nI got some nice templates though\band some stuff left to cleanup" +
		"\nWhat is left to be done?" +
		"\nQuality of Life,\bmore unit tests,\blogin with google, etc." +
		"\nAh and i somehow forgot\bto add a user account management...\bnobody needs that." +
		"\nI just love development <3",

	// Quotes
	quotes: [
	/*1*/	"Give a human a fish and\bhe eats for a day.\nGive a human a barrel of fish\band he eats for a week.",
	/*2*/	"If you look into the abyss\bthen the abyss also looks\bout of you into you.",
	/*3*/	"But do you know why I love\bCini Minis so much?",
	/*4*/	"Super powers exist!\nThey are just not that good.\bLike freckles or allergies.",
	/*5*/	"The debugger is\bdocumentation enough",
	/*6*/	"I like very\benthusiastic walks.",
	/*7*/	"I love\bsoftware architecture.",
	/*8*/	"There is no crypto mining\bgoing on in the background.",
	/*9*/	"Did you you leave\byour stove on?",
	/*10*/	"Why is there no spread\bwith bread topping?",
	/*11*/	"Butter, butter, butter, butter.",
	/*12*/	"Are enemies the friends\bwe did not find along the way?",
	/*13*/	"Does existence make more sense\bthan nonexistence?",
	/*14*/	"What happened to those tomatoes?",
	/*15*/	"People tend to be neither Kjeld nor snowmen",
	],
	quoterPrefix: [
		"",
		"~ ",
		"by ",
	],
	quoter: [
		"Tim Beier",
		"T. Beier",
		"T.B.",
		"Tim B.",
		"Tim Beier",
		"T. Beier",
		"T.B.",
		"Tim B.",
		"Tim",
		"Tim",
	],
};

const de: IBaseStrings = {
	//App Names
	AppName: "Homepage",

	// Languages
	Language: "Sprache",
	english: "Englisch",
	german: "Deutsch",

	// Pages
	Home: "Home",
	About: "Über",
	Contact: "Kontakt",
	LegalNotice: "Impressum",

	// Utility
	loading: "loading",
	more: "INFO",
	DarkMode: "Dunkel",
	BrightMode: "Hell",
	SUBMIT: "SENDEN",
	Info: "Info",
	Download: 'Herunterladen',
	GoogleDrive: 'Google Drive',

	// Legal Notice
	firstName: "Tim",
	secondName: "Beier",
	myCity: "Hamburg",
	email: "contact[at]timbeier.com",
	addressLine1: "c/o Block Services",
	addressLine2: "Stuttgarter Str. 106",
	addressLine3: "70736 Fellbach",
	addressLine4: "",
	companyName: "",

	// Contact
	contactMeInfo: "Fülle das Formular aus und wir melden uns innerhalb von 24 Stunden.",
	FirstName: "Vorname",
	EnterFirstName: "Vorname eingeben",
	LastName: "Nachname",
	EnterLastName: "Nachname eingeben",
	Email: "Email",
	EnterEmail: "Email eingeben",
	Message: "Nachricht",
	Subject: "Thema",
	EnterSubject: "Thema eingeben",
	TypeYourMessageHere: "Schreibe deine Nachricht hier",
	sendingEmailSuccessful: "Email erfolgreich versendet",

	// About
	Team: "Über",
	teamMember01Name: "Tim Beier",
	teamMember01Header01: "Info",
	teamMember01Info01: "Hallo!" +
		"\nIch bin Tim" +
		"\nLebe in Hamburg" +
		"\nEntwickle seit 2012",
	teamMember01Header02: "Erfahrung",
	teamMember01Info02:
		"\n Software Architektur" +
		"\n 3D-Modellierung" +
		"\n Serious games" +
		"\n Mobile games" +
		"\n Game-Design" +
		"\n Web games" +
		"\n Web Shops" +
		"\n Web 3D" +
		"\n VR",
	teamMember01Header03: "Interests",
	teamMember01Info03:
		"Software Architektur" +
		"\nHolzarbeiten" +
		"\nGame design" +
		"\nBrettspiele" +
		"\nPen & Paper" +
		"\nWissenschaften",
	teamMember01Header04: "Trivia",
	teamMember01Info04:
		"Ich liebe es neue Leute kennenzulernen" +
		"\nSchreib mir einfach",



	// Projects
	Gameplay: "Spielablauf",
	Trailer: "Trailer",

	// Dino
	dinoKingName: "Dino King",
	dinoKingThumbnailDescription: "Wirf Schinken um dich gegen die hungrigen Dinos zu wehren!",
	dinoKingSectionDescription_01: "In Dino King\bwehrt sich ein Neandertaler gegen Dinosaurier." +
		"\nDinos werden durch das werfen\bvon Fleischkeulen abgewehrt.\b" +
		"\nDer Spieler malt mit seinem Finger\bdie Flugbahn des Schinkens\bund versucht so lange wie möglich\bnicht gefressen zu werden.",
	dinoKingSectionHeader_02: "Gegner",
	dinoKingSectionDescription_02: "Die unaufhörlichen Horden von Dinosauriern.\bSind sie nicht süß?\bSie sind so süß.\bGib es zu!",

	// Skyblock
	skyBlockName: "Sky Block",
	skyBlockDescription: "Eine lowpoly Landschaft im pigart Stil",
	skyBlockSectionHeader_01: "Beschreibung",
	skyBlockSectionDescription_01: "Eine schön gerenderte 3D-Landschaft\bim Pigart-Stil.",

	// Corridor
	corridorName: "3D Korridor",
	corridorDescription: "Ein High-Poly 3D-Modell eines Korridors",
	corridorSectionHeader_01: "Beschreibung",
	corridorSectionDescription_01: "Dieses Rendering zeigt ein\bhigh-poly 3D-Modell\beines Sci Fi Korridors.",
	corridorSectionHeader_02: "Referenz",
	corridorSectionDescription_02: "Der oben abgebildete\b3D-Korridor basiert auf diesem Konzept\bvon Abraham Wong Shih Yaw.",

	// Door
	doorName: "3D Sci-Fi Door",
	doorDescription: "Ein low-poly 3D-Modell einer SciFi Tür",
	doorSectionHeader_01: "Beschreibung",
	doorSectionDescription_01:  "Dieses Rendering zeigt\bein low-poly 3D-Modell\beiner Sci-Fi Tür." +
		"\nEs besteht aus einem Rahmen\bmit vier beweglichen Teilen\bund insgesamt 300 Tris." +
		"\nDie folgenden Texture-Maps\bwerden verwendet:\bDiffuse, Specular, Normal",

	// Shield
	shieldName: "3D Schild",
	shieldDescription: "Ein Low-Poly 3D-Modell eines Schildes",
	shieldSectionHeader_01: "Beschreibung",
	shieldSectionDescription_01: "Dieses Rendering zeigt ein\blow-poly 3D-Modell\beines Schildes mit einem Totenschädel." +
		"\nDas Bild besitzt ein partielles\bOverlay der 3D-Mesh Topologie.",
	shieldSectionHeader_02: "Rückseite",
	shieldSectionDescription_02: "Die Referenz besitzt keine Rückseite;\bdaher habe ich mir eine ausgedacht.",
	shieldSectionHeader_03: "Seite",
	shieldSectionDescription_03: "Dieses Bild zeigt die Schokoladenseite.",
	shieldSectionHeader_04: "Referenz",
	shieldSectionDescription_04: "Das oben abgebildete\b3D-Schild basiert auf diesem Konzept\bvon Artyom Vlaskin.",

	// Spider
	spiderName: "3D Mechanische Spinne",
	spiderDescription: "Ein Low-Poly 3D-Modell einer mechanischen Spinne",
	spiderSectionHeader_01: "Vorderseite",
	spiderSectionDescription_01: "Dieses Rendering zeigt ein\blow-poly 3D-Modell\beiner mechanischen spinne." +
		"\nDiesem Modell liegt keine Referenz zugrunde\bund entsprang meiner Fantasie." +
		"\nDie Spinne besitzt einen Motorblock\bDie Beine sind hydraulisch\bbeweglich und animierbar.",
	spiderSectionHeader_02: "Rückseite",
	spiderSectionDescription_02: "In der Rückseitenansicht wird\bdas Zahnradgetriebe sichtbar." +
		"\nzudem wird ersichtlich,\bdass der Motorblock an Ketten hängt.",
	spiderSectionHeader_03: "Vorderseite Topologie",
	spiderSectionDescription_03: "Die Front besitzt ein\bOverlay der 3D-Mesh Topologie.",
	spiderSectionHeader_04: "Rückseite Topologie",
	spiderSectionDescription_04: "Die Rückseite besitzt ein\bOverlay der 3D-Mesh Topologie.",

	// Staff
	staffName: "3D Stab",
	staffDescription: "Ein Low-Poly 3D-Modell eines Stabs",
	staffSectionHeader_01: "Beschreibung",
	staffSectionDescription_01: "Dieses Rendering zeigt ein\blow-poly 3D-Modell eines Stabs." +
		"\nDas Bild besitzt ein partielles\bOverlay der 3D-Mesh Topologie." +
		"\nDie folgenden Texture-Maps\bwerden verwendet:\bDiffuse, Normal",
	staffSectionHeader_02: "Referenz",
	staffSectionDescription_02: "Der oben abgebildete 3D-Stab\bbasiert auf diesem Konzept\bvon Roman Guro.",

	// Planets
	planetsName: "Planeten",
	planetsDescription: "Ein paar low-poly 3D Planeten",
	planetsSectionHeader_01: "Ein Planet",
	planetsSectionDescription_01: "Einfach nur ein Rendering\beines low-poly 3D Planeten.",
	planetsSectionHeader_02: "Mehr Planeten",
	planetsSectionDescription_02: "Mehr Planeten halt",

	// SymbolQuest
	symbolQuestName: "Symbol Quest: Riddle Challenge",
	symbolQuestDescription: "Symbol Quest ist ein 2D Puzzle Game von 2015",
	symbolQuestSectionHeader_01: "Beschreibung",
	symbolQuestSectionDescription_01: "Symbol Quest: Riddle Challenge wurde\b2015 von Red Orb Games auf\bAndroid und iOs veröffentlicht.",
	symbolQuestSectionHeader_02: "Spielablauf",
	symbolQuestSectionDescription_02: "Um Bilder aufzudecken zieht der\bSpieler Linien über den Bildschirm." +
		"\nDort wo Linien sich mit dem\bversteckten Motiv überlagern\bwird dieses aufgedeckt." +
		"\nDer Spieler soll mit einer begrenzten\bLinienanzahl das Motiv erraten.",

	// TerribleTower
	terribleTowerName: "Terrible Tower",
	terribleTowerDescription: "Terrible Tower ist ein 2D Roguelite Puzzle Platformer von 2014",
	terribleTowerSectionHeaderTrailer: "Trailer",
	terribleTowerSectionDescriptionTrailer: "The Release-Trailer.",
	terribleTowerSectionHeader_00: "Beschreibung",
	terribleTowerSectionDescription_00: "Terrible Tower wurde 2015\bvon Red Orb Games auf\bAndroid und iOs veröffentlicht.",
	terribleTowerSectionHeader_01: "Spielablauf",
	terribleTowerSectionDescription_01: "Der Spieler muss einen magischen Turm erklimmen." +
		"\nHierzu muss er durch den unteren\bBildschirmrand springen um am\boberen Bildschirmrand wieder aufzutauchen.\bMagie!" +
		"\nDer Turm besteht aus 10 Zeitaltern\bmit unterschiedlichen Gefahren\bund beginnt in der Steinzeit",
	terribleTowerSectionHeader_02: "Runenzeitalter",
	terribleTowerSectionDescription_02: "Das Runenzeitalter leuchtet magisch\bund enthält gefährliche Feuer\bsowie magische Geschosse.",
	terribleTowerSectionHeader_03: "Zeitalter Roms",
	terribleTowerSectionDescription_03: "Im römischen Zeitalter erschweren Ballisten,\bMorgensterne und Fallsteine den Weg.",
	terribleTowerSectionHeader_04: "Mittelalter",
	terribleTowerSectionDescription_04: "Im Mittelalter schwingen unzählige Äxte.\bDucken!",
	terribleTowerSectionHeader_05: "Industriezeitalter",
	terribleTowerSectionDescription_05: "Das Industriezeitalter enthält\bKreissägen und hydraulische Pressen.",
	terribleTowerSectionHeader_06: "Art-deco Zeitalter",
	terribleTowerSectionDescription_06: "Nun fliegen die Kreissägen sogar schon\bund rotierende Flammenwerfer\bsowie automatische Geschütze sind überall.",
	terribleTowerSectionHeader_07: "Atomzeitalter",
	terribleTowerSectionDescription_07: "Das Atomzeitalter ist die Zeit\bvon freien Radikalen,\bgiftigen Fässern und Atomraketen.",
	terribleTowerSectionHeader_08: "Matrix",
	terribleTowerSectionDescription_08: "In der Matrix versperren\bLaser regelmäßig den Weg und\bBomben den Weg.\bUnd alles bewegt sich ständig!",
	terribleTowerSectionHeader_09: "Scifi Zeitalter",
	terribleTowerSectionDescription_09: "Rotierende Laser-Geschütztürme\büberall und alles bewegt sich ständig!",
	terribleTowerSectionHeader_10: "Endzeit",
	terribleTowerSectionDescription_10: "In der Endzeit sind Fallen aus\ballen vorherigen Zeitaltern vorhanden.\bKomplett willkürlich!",
	terribleTowerSectionHeader_11: "Hauptmenü",
	terribleTowerSectionDescription_11: "Das Hauptmenü ermöglicht das Kaufen\bvon Upgrades, Skins und die Einsicht\bvon Erfolgen sowie Highscores.",

	// Bachelor
	bachelorThesis: "Bachelorarbeit",
	bachelorName: "Navigation in non-euclidean VR environments",
	bachelorDescription: "Dies ist die Abschlussarbeit für mein Studium der angewandten Informatik.",
	bachelorSectionHeader_01: "Beschreibung",
	bachelorSectionDescription_01: "Dies ist die Abschlussarbeit für\bmein Studium der angewandten Informatik." +
		"\nEs wurde untersucht ob sich\bnicht-euklidische VR-Umgebungen eignen\bum Navigationszeiten in unbekannten\bUmgebungen zu verkürzen.",
	bachelorSectionHeader_02: "Experiment",
	bachelorSectionDescription_02: "20 Probanden hatten die Aufgabe\b20 Landmarken in einer VR-Umgebung zu finden." +
		"\nEs gab eine euklidische\bsowie eine nicht-euklidische\bVR-Umgebung und beide waren\bgleich aufgebaut." +
		"\nDie nicht-euklidische Umgebungb\bbesaß kürzere Laufwege durch\bKrümmungen des Raumes." +
		"\nProbanden in der nicht-euklidischen\bVR-Umgebung hatten keine Probleme\bmit räumlichen Überlagerungen\bund waren im Schnitt 40% schneller.",
	bachelorSectionHeader_03: "Hyperbolische Raumkrümmung",
	bachelorSectionDescription_03: "In Abbildung a) sind 5 miteinander\bverbundene Räume abgebildet.\bDie Türen sind mit Zahlen markiert." +
		"\nEin solcher Raum ist im 3-dimensionalem\bRaum nicht möglich, da sich Räume\büberlagern würden." +
		"\nEr wird nur durch eine hyperbolische\bRaumkrümmung wie in b) ermöglicht.",
	bachelorSectionHeader_04: "Elliptische Raumkrümmung",
	bachelorSectionDescription_04: "Die Räume in a) sind nur durch eine\belliptische Raumkrümmung miteinander zu\bverbinden wie sie in b) gezeigt wird." +
		"\nHierdurch wird die Strecke\bzwischen den Räumen verkürzt.",
	bachelorSectionHeader_05: "Streckenersparnis",
	bachelorSectionDescription_05: "Diese Abbildung zeigt wie eine\bWegstrecke zwischen zwei Räumen verkürzt\bwerden kann wenn diese sich\büberlagern dürfen.",
	bachelorSectionHeader_06: "Streckenersparnis aus Egoperspektive",
	bachelorSectionDescription_06: "In der Abbildung blickt\bein Proband auf zwei Türen." +
		"\nDie linke führt in einen Flur\bund die rechte in eine Garage.\bEs ist aus diesem Ausschnitt schon ersichtlich,\bdass ein solches Raumlayout unmöglich sein sollte." +
		"\nIn diesem Fall wird es\bdurch Portale ermöglicht.",
	bachelorSectionHeader_07: "Euklidische VR-Umgebung",
	bachelorSectionDescription_07: "In dieser Abbildung wird die\beuklidische VR-Umgebung\baus der Vogelperspektive gezeigt.",
	bachelorSectionHeader_08: "Nicht-euklidische VR-Umgebung",
	bachelorSectionDescription_08: "In dieser Abbildung wird die\bnicht-euklidische VR-Umgebung\baus der Vogelperspektive gezeigt." +
		"\nDie Räume sind voneinander separiert\bdamit sie sich nicht überlagern\bund bleiben durch Portale 'P'\bmiteinander verbunden." +
		"\nDie farbigen Linien geben\bdie Wege zwischen den Räumen an.",
	bachelorSectionHeader_09: "Komponentendiagramm",
	bachelorSectionDescription_09: "In dieser Abbildung sind\bdie Komponenten abgebildet,\bdie das Experiment ermöglichen." +
		"\nBevor ich das Diagramm hier erläutere\bverweise ich direkt auf meine Bachelorarbeit,\bdie nachfolgend heruntergeladen werden kann.",
	penAndPaperVrName: "Pen and Paper VR",
	penAndPaperVrDescription: "Ein Hochschulprojekt über die Anwendbarkeit von Pen and Paper in VR",
	penAndPaperVrSectionHeader_01: "Beschreibung",
	penAndPaperVrSectionDescription_01: "Spieler können Einheiten erschaffen\bund auf einem Spielfeldraster platzieren." +
		"\nDas Spielfeld kann durch Pfeile\bauf dem Tisch bewegt werden kann.",
	penAndPaperVrSectionHeader_02: "Charakterbögen",
	penAndPaperVrSectionDescription_02: "Die Einheiten besitzen\beditierbare Charakterbögen." +
		"\nZur Bearbeitung wird eine\bvirtuelle Tastatur verwendet." +
		"\nDie Charakterbögen können\bgespeichert und geladen werden.",
	penAndPaperVrSectionHeader_03: "Level",
	penAndPaperVrSectionDescription_03: "Auf dem Spielfeld kann\ballerlei Unrat platziert werden." +
		"\nElemente außerhalb des sichtbaren\bRasters werden automatisch ausgeblendet.",
	penAndPaperVrSectionHeader_04: "Getrackter Würfel",
	penAndPaperVrSectionDescription_04: "In der Hochschule hatten\bwir Zugang zu Tracking-Kameras." +
		"\nMit diesem Würfel konnte in echtzeit\bin der VR-Umgebung gewürfelt werden.",

	// MachineLearning
	machineLearningPaper: "AgentenAusarbeitung",
	machineLearningName: "Maschinelles Lernen",
	machineLearningDescription: "Agenten werden zufällige Regelsätze und Teams zugewiesen und kämpfen um ihr Überleben",
	machineLearningSectionHeader_01: "Beschreibung",
	machineLearningSectionDescription_01: "Dies ist ein Ausschnitt aus\beinem meiner Universitätsprojekte\büber komplexe, adaptive Systeme." +
		"\nAgenten kämpfen in Teams um\bRessourcen und ihr Überleben.",
	machineLearningSectionHeader_02: "Beschreibung",
	machineLearningSectionDescription_02: "Jeder Agent hat einen\bzufälligen Satz von Regeln.\bJede Regel und jeder Zug hat Kosten." +
		"\nZu diesen Regeln gehört z.B. zu fliehen,\bwenn man in der Unterzahl ist\boder nicht gegen Hindernisse zu laufen." +
		"\nDieses Bild zeigt wie Regeln von\boben nach unten gehandhabt werden. " +
		"\nDer Kurs wurde in deutscher Sprache abgehalten\bund daher ist auch die folgende\bAusarbeitung auf deutsch.",

	// PimpTheLimb
	pimpTheLimbName: "Kanonensprung",
	pimpTheLimbDescription: "Ein Prototyp in dem eine Kanone gespielt wird",
	pimpTheLimbSectionHeader_01: "Beschreibung",
	pimpTheLimbSectionDescription_01: "Dieses Video zeigt die Grundmechanik." +
		"\nDer Spieler kann leicht nach\blinks und rechts rollen." +
		"\nDas Schießen hat einen Rückstoß\bund kann zum Springen genutzt werden." +
		"\nMunition wird durch\bDrehungen nachgeladen." +
		"\nDie Munition wird auf\bder Kanone angezeigt.",
	pimpTheLimbSectionHeader_02: "Version 1",
	pimpTheLimbSectionDescription_02: "Dies ist die erste Version." +
		"\nDiese Idee basiert auf Mount Your Friend." +
		"\nVier Kanonen sind jedoch unbedienbar.",
	pimpTheLimbSectionHeader_03: "Version 2",
	pimpTheLimbSectionDescription_03: "Durch eine Vereinfachung wird\bnur noch eine Kanone verwendet." +
		"\nDafür kann die Figur leicht zu den Seiten rollen." +
		"\nDiese Idee wurde aufgegeben\bda das Leveldesign ein Albtraum ist.",

	// Portals
	portalGunName: "Portalkanone",
	portalGunDescription: "Ein Prototyp einer selbstgebauten Portalpistole",
	portalGunSectionHeader_01: "Beschreibung",
	portalGunSectionDescription_01: "Der Spieler kann zwei Projektile verschießen,\bdie zwei verbundene Portale erzeugen." +
		"\nBeim Aufprall finden Projektile\bdie beste Positionierung\bund Ausrichtung für Portale." +
		"\nPortale ermöglichen einen nahtlosen\bÜbergang von Spielern und Objekten." +
		"\nDie Immersion wird nur durch die\bSchatten der Beleuchtung unterbrochen." +
		"\nFür die Umsetzung wurden mehrere\bKameras und Kollisionsebenen verwendet.",

	// Empfängnis
	empfaengnisName: "Empfängnis",
	empfaengnisDescription: "Ein Fantasy-Roman, den ich mit 2 Freunden während der Schulzeit geschrieben habe",
	empfaengnisSectionHeader_01: "Empfängnis",
	empfaengnisSectionDescription_01:  "Dieser Fantasy-Roman wurde\bSeite für Seite geschrieben,\bwährend wir uns abwechselten." +
		"\nIch habe meine Seite\ban Patrick weitergegeben,\bPatrick an Kjeld\bund Kjeld an mich." +
		"\nDas führte dazu, dass jedem Autor\bInformationen fehlten und\burkomische Wendungen enstanden." +
		"\nWenn du das Leben nicht zu ernst nimmst,\bsolltest du es lesen." +
		"\nNebenbemerkung:\bIch wurde wegen des Namens überstimmt.",

	// StealthCommander
	stealthCommanderName: "Einheiten auf Kurven bewegen",
	stealthCommanderDescription: "Ein Prototyp für ein kurvenbasiertes Bewegungssystem",
	stealthCommanderSectionHeader_01: "Beschreibung",
	stealthCommanderSectionDescription_01: "Einheiten können ausgewählt und befehligt werden." +
		"\nSich bewegende Einheiten haben ihren Pfad\bangezeigt und jeder Pfad hat einen Griff." +
		"\nDas Bewegen des Griffs verändert den Pfad,\bwodurch eine Kurve entsteht." +
		"\nDie maximale Krümmung ist proportional\bzur Länge des Pfad.",

	// GrimRepair
	grimRepairName: "Grim Repair",
	grimRepairDescription: "Global Game Jam von 2020 mit dem Thema 'Reparatur'",
	grimRepairSectionHeader_01: "Beschreibung",
	grimRepairSectionDescription_01: "In Grim Repair hat der\bGrim Reaper alle Handwerker getötet." +
		"\nJetzt ist es deine Aufgabe Maschinen zusammenzubauen." +
		"\nDiese Maschinen müssen dann den Grim Reaper\bangreifen, um ihn aufzuhalten." +
		"\nEs stellt sich heraus, dass das ganze Spiel\bauf einem noch größeren Grim Reaper aufgebaut wurde.",

	// Catastrophic
	catastrophicName: "Catastrophic",
	catastrophicDescription: "Global Game Jam of 2019 mit dem Thema 'Zuhause'",
	catastrophicSectionHeader_01: "Beschreibung",
	catastrophicSectionDescription_01: "In Catastrophic demoliert\beine Katze dein Zuhause." +
		"\nDu bist ein Roboter mit magnetischen Kräften\bund hast die Aufgabe alles zu reparieren." +
		"\nSammle und schieße Schraubenzieher\bum Dinge zu reparieren." +
		"\nBeachte, dass das Reparieren von Dingen\bweitere Katzen, Schraubenzieher\bund Verrücktheiten anziehen kann." +
		"\nIch liebe einfach Fluid-Simulationen.",

	// Homepage3D
	homepage3DName: "Made of Cubes",
	homepage3DDescription: "Meine vorherige Homepage die zu 100% aus 3D Würfeln besteht",
	homepage3DSectionHeader_01: "Beschreibung",
	homepage3DSectionDescription_01: "Ich kann mich nicht daran erinnern\bwie ich auf die absurde Idee kam\beine Homepage zu 100% in 3D zu bauen." +
	"\nAber hey es sieht cool aus\bwenn Würfel umherfliegen." +
	"\nFunfact 1: Alle auf der Homepage verwendeten Würfel\bsind Klone des abgebildeten Ladesymbols."+
	"\nFunfact 2: Für flüssige FPS ist eine dedizierte GPU\berforderlich, und die Anwendung kann\bdiese nicht selbst zuweisen.",
	homepage3DSectionHeader_02: "Eigener Font",
	homepage3DSectionDescription_02: "Ich hatte die grandiose Idee auch\bden gesamten Text aus 3D Würfeln zu erstellen." +
		"\nAlso warum den Holzweg nicht zuende gehen\bund einen eigenen low-poly Font bauen!",
	homepage3DSectionHeader_03: "Würfel animieren",
	homepage3DSectionDescription_03: "Es ist dann auch noch sehr viel cooler\bwenn Würfel umherfliegen um Text darzustellen." +
		"\nEs stellt sich heraus, dass tausende Animationen (Tweens)\bfür Position, Rotation, Skalierung und Farbe\bdann doch rechenintensiv werden" +
		"\nDaher werden ausnahmslos alle Animationen\bauf der Seite bereits beim Aufruf berechnet.",
	homepage3DSectionHeader_04: "Würfel formen Kontaktseite",
	homepage3DSectionDescription_04: "Die Würfel sind nun an ihre\bPositionen geflogen und gedreht." +
		"\nDamit Interaktionen möglich werden\bwerden Raycasts der Maus verwendet\bum Wörter einfärben zu können." +
		"\nNicht vorhandene Würfel für\blängere Texte werden automatisch generiert.",
	homepage3DSectionHeader_05: "Portfolio",
	homepage3DSectionDescription_05: "Zuletzt müssen auch noch meine\bProjekte angezeigt werden." +
		"\nHierfür werden diese als Bilder\bauf den gleichen Würfel angezeigt." +
		"\nBeim Seitenwechsel werden die Bilder\bgenau dann ausgetauscht wenn die Objekte\borthogonal zur Kamera sind." +
		"\nDas waren leider keine 90 Grad Winkel\bweil die Kamera perspektivisch ist." +
		"\nIch habe auch Videos in diesem\b3D Raum auf den Würfeln eingebunden. " +
		"\nEs war ein Alptraum, da\bzusätzliche Kameras benötigt werden." +
		"\nVor allem beim Zoomen.",

	// Homepage2D
	homepage2D: "Homepage 2D",
	homepage2DDescription: "Entwicklung dieser Homepage",
	homepage2DSectionHeader_01: "Homepage 2D",
	homepage2DSectionDescription_01: "Beschreibung meiner Homepage\auf meiner Homepage. Fun!",
	homepage2DSectionHeader_02: "Rendering",
	homepage2DSectionDescription_02: "Ich verwende Material-UI zum\bStylen von Elementen & für Themes." +
		"\nIch habe mich für React entschieden,\bda ich langsam meine\bwiederverwendbaren Komponenten aufbaue." +
		"\nRedux ist eventuell übertrieben\bfür so ein kleines Projekt,\baber ich verwende es auch als Vorlage\bfür andere Projekte.",
	homepage2DSectionHeader_03: "Themes",
	homepage2DSectionDescription_03: "Material-UI ermöglicht\beine einfache Verwendung für Themes." +
		"\nDamit gibt es einen hellen und einen dunklen Modus <3",
	homepage2DSectionHeader_04: "Styling",
	homepage2DSectionDescription_04: "Styling mit Material-UI",
	homepage2DSectionHeader_05: "Redux Store",
	homepage2DSectionDescription_05: "Der Redux-Store ermöglicht es\buns den State global zu verwalten." +
		"\nFür die Anzeige von Toasts unter Verwendung des AlertState oder die Aktualisierung der Sprachauswahl unter Verwendung des languageState.",
	homepage2DSectionHeader_06: "Beispiel: Kontakt-Seite",
	homepage2DSectionDescription_06: "Aufgrund der benutzten Bibliotheken\bsind die Seiten schön kompakt." +
		"\nDie Komponente enthält Typdefinitionen\bfür props, die an die Komponente\bübergeben werden." +
		"\nSie enthält zudem einen internen State und\bFunktionen zur Verwaltung von Eingaben." +
		"\nUnd zuletzt wird der globale State,\bz. B. für Alerts und Styles,\bauf die Komponente abgebildet.",
	homepage2DSectionHeader_07: "Kompilierung",
	homepage2DSectionDescription_07: "Um die Javascript-Hölle\bzu vermeiden wird Typescript verwendet. " +
		"\nIch liebe Typescript." +
		"\nWebpack kompiliert\bden Code in ein kleines Paket." +
		"\nDocker weil Docker! Hurra!",
	homepage2DSectionHeader_08: "Projektkonfigurationsbeispiel: Portale",
	homepage2DSectionDescription_08: "Jedes Projekt dieser Homepage\bis in einer Config definiert." +
		"\nDas macht es einfacher,im Laufe\bder Zeit neue Projekte hinzuzufügen." +
		"\nDieser Block allein erstellt\bein Kartenelement auf der Hauptseite\bund auch eine komplette Unterseite." +
		"\nDiese Seite ist auch eine\bsolche Konfiguration.",
	homepage2DSectionHeader_09: "Projekt-Konfigurationsarten",
	homepage2DSectionDescription_09: "Eine Projektseite besteht aus\ball diesen Typdefinitionen." +
		"Einige sind optional wie\bz.B. Videos, Bilder, Downloads usw.",
	homepage2DSectionHeader_10: "Docker-Datei",
	homepage2DSectionDescription_10: "Hier gibt es nicht viel zu sagen." +
		"\nPakete installieren, einen Build\berstellen, der Build enthält\bdann die public index.html.",
	homepage2DSectionHeader_11: "Deployment",
	homepage2DSectionDescription_11: "Der Code liegt auf Github." +
		"\nDynadot ist mein Domain-Provider." +
		"\nNetlify wird für die automatische\bBereitstellung und das Hosting verwendet.",
	homepage2DSectionHeader_12: "Nützliche Bibliotheken",
	homepage2DSectionDescription_12: "Imagekit.io ermöglicht\bdas Hochladen von Bildern." +
		"\nDamit wird diese Homepage\bschneller geladen, da Bilder\bnur bei Bedarf heruntergeladen werden." +
		"\nBilder werden zudem automatisch\bauf die gewünschte Größe angepasst." +
		"\nEmailJs ermöglicht einen sehr\beinfachen Versand von E-Mails.",
	homepage2DSectionHeader_13: "ImageKit Io",
	homepage2DSectionDescription_13: "Dies ist ein Screenshot meines\bImagekit.io Ornders, der\balle Bilder dieser Homepage enthält.",

	// BoxOfCards
	boxOfCards: "Box of Cards",
	boxOfCardsDescription:
		"Ein anpassbares Kartenspiel zum Erzählen von Geschichten mit Freunden und sehr viel Overengineering.",
	boxOfCardsWebappSectionHeader_01: "Spiel-Design",
	boxOfCardsWebappSectionDescription_01:
		"In Box of Cards werden den\bSpielern Antworten gegeben,\bdie sie in Sätze einfügen können.",
	boxOfCardsWebappSectionHeader_02: "Anpassbare Karten",
	boxOfCardsWebappSectionDescription_02:
		"Karten können Dropdowns haben,\bum sie besser an den Satz\banpassen zu können.",
	boxOfCardsWebappSectionHeader_03: "Deck Auswahl",
	boxOfCardsWebappSectionDescription_03:
		"Der Host kann entscheiden,\bob er ein oder mehrere Decks\bfür das Spiel verwenden möchte.",
	boxOfCardsWebappSectionHeader_04: "Decks erstellen",
	boxOfCardsWebappSectionDescription_04:
		"Spieler können ihre eigenen Decks\berstellen und direkt testen. " +
		"\nDie Sätze enthalten\bAntwortplatzhalter durch '_'." +
		"\nAntworten können austauschbare Wörter haben,\bindem diese Wörter komma-getrennt\bin eckige Klammern [eins, zwei]\bgesetzt werden.",
	boxOfCardsWebappSectionHeader_05: "Mehrspieler-Lobby",
	boxOfCardsWebappSectionDescription_05:
		"Öffentliche Spiele können\bvon jedem eingesehen werden. " +
		"\nIch gebe zu, dass ich bei der Wahl\bder Testnamen faul war.",
	boxOfCardsWebappSectionHeader_06: "Spiel-Lobby",
	boxOfCardsWebappSectionDescription_06:
		"Der Host kann die Spieleinstellungen\bin der Spiel-Lobby festlegen." +
		"\nAußerdem bestätigt er beitretende Spieler." +
		"\nSobald alle Spieler bereit sind,\bkann das Spiel beginnen.",
	boxOfCardsWebappSectionHeader_07: "Spiel",
	boxOfCardsWebappSectionDescription_07:
		"Basierend auf den Einstellungen\bwird ein Satz ausgewählt\bund die Spieler reichen\bihre Antworten zur Abstimmung ein." +
		"\nIn diesem Bild können\bbis zu zwei Antworten\beingereicht werden." +
		"\nEs wurde bereits eine Runde gespielt\bund haben daher eine abgeschlossene Karte\bin ihrer Historie am oberen Bildrand.",
	boxOfCardsArchitectureSectionHeader_00: "Overengineering",
	boxOfCardsArchitectureSectionDescription_00:
		"Ich habe dieses Projekt begonnen,\bweil ich viele, neue\bTechnologien ausprobieren wollte." +
		"\nAll das hätte man auch\bmit einem Express-Server\bauf einem Raspberry Pi machen können." +
		"\nEin weiser Mensch sagte einst:" +
		"\nGib einem Mann einen Fisch\bund er isst für einen Tag." +
		"\nGib einem Mann eine\bFischereiboot-Industrie und er\bwird nie wieder einen Fisch sehen.",
	boxOfCardsArchitectureSectionHeader_01: "Komponenten",
	boxOfCardsArchitectureSectionDescription_01:
		"Was habe ich nun gewählt,\bwenn ein node.js-Server\bausgereicht hätte?" +
		"\nWie wäre es mit einem\bKubernetes-Cluster mit Microservices!" +
		"\nUnd wie funktioniert das Ganze?" +
		"\nFunktioniert das Ganze?" +
		"\nFinden wir es heraus!",
	boxOfCardsArchitectureSectionHeader_02: "Web Client",
	boxOfCardsArchitectureSectionDescription_02:
		"Der Web-Client wird kompiliert\bund anschließend von einem\bexpress-Server bereitgestellt." +
		"\nDer express Server wird in einem\bDocker-Container ausgeführt." +
		"\nUnd der Docker Container\bwird durch ein Kubernetes Deployment\b.... deployed." +
		"\nZuletzt verweist die Domain\bauf einen Load-Balancer-Service,\bder auf den Express-Server verweist.",
	boxOfCardsArchitectureSectionHeader_03: "Backend-Dienste",
	boxOfCardsArchitectureSectionDescription_03:
		"Drei Dienste verwalten\bdie Mehrspieler-Logik." +
		"\nDie Mehrspieler-Lobby informiert\büber offene Spiel-Lobbies." +
		"\nDie Spiel-Lobby verwaltet...\bdie Spiel-Lobby." +
		"\nSobald der Host auf den Start\bin der GameLobby klickt,\bmigrieren alle Spieler\bauf einen Spiel-Server",
	boxOfCardsArchitectureSectionHeader_04: "Redis Cluster",
	boxOfCardsArchitectureSectionDescription_04:
		"Redis wird zum Cachen von Daten verwendet,\bdie für diese Backend-Dienste\bbenötigt werden." +
		"\nZum Beispiel speichert es,\bwelcher Spieler welchem Spiel\boder welcher GameLobby\bzugeordnet sind." +
		"\nDies verhindert, dass Spieler\bin mehreren Spielen sind\boder sich nicht wieder\bverbinden können.",
	boxOfCardsArchitectureSectionHeader_05: "RabbitMq Broker",
	boxOfCardsArchitectureSectionDescription_05:
		"Der Broker ermöglicht es,\bClient-Sitzungen lose\bmit Backend-Diensten\bzu koppeln\bund so Skalierung\bzu ermöglichen." +
		"\nWenn sich ein Client über\bdie Domain verbindet, muss er\bWebsockets (WebStomp-Protokoll)\bverwenden, weil wegen\bBrowser-Limitierungen und so." +
		"\nDie Backend-Dienste\bsind Express-Server\bund verwenden daher\bdas AMQP-Protokoll.",
	boxOfCardsArchitectureSectionHeader_06: "Google Cloud",
	boxOfCardsArchitectureSectionDescription_06:
		"Warum um alles in der Welt\bverwende ich zusätzlich zu Kubernetes\bauch noch eine Google Cloud?" +
		"\nNun, mein ursprünglicher Plan war es,\bFirebase zu verwenden,\bum den Gamestate zu verwalten,\baber meine Berechnungen führten\bzu meinem Bankrott." +
		"\nIch verwende aber immer noch\bGoogle Cloud Funktionen\bum Authentifizierungen zu verwalten\bund Decks zu speichern." +
		"\nEs ist nicht nötig,\bdie Authentifizierung selbst\bzu implementieren." +
		"\nCloud Functions sind Spitze!",
	boxOfCardsArchitectureSectionHeader_07: "Mein Cluster im Überblick",
	boxOfCardsArchitectureSectionDescription_07:
		"Dies sind alle Dienste\bund Konfigurationsdateien\bmeines laufenden Clusters,\bobwohl einige,\bpersistente Volumes\bnicht angezeigt werden." +
		"\nEs gibt nur einen Pod\bvon jedem Dienst,\bda ich den günstigsten\bverfügbaren Node-Plan verwende." +
		"\nWarum die Neustarts?\bDer Broker braucht einige Zeit,\bum hochzufahren, und ich muss noch\bReadiness-Probes hinzufügen.",
	boxOfCardsArchitectureSectionHeader_08: "Digital Ocean Dashboard",
	boxOfCardsArchitectureSectionDescription_08:
		"Nichts Ausgefallenes,\bnur mein Cluster\bund die beiden Load-Balancer,\bdie auf ihn zeigen." +
		"\nEiner für den Abruf des Webclients\bund einer für den Zugriff\bauf den Broker für die\bMehrspieler-Interaktion." +
		"\nWarum kann das nicht\bein einziger Loadbalancer sein?\b" +
		"\nNun, ich habe schon einiges ausprobiert,\baber aufgrund der Port-Anforderungen\bfür die verschiedenen Dienste\bfunktioniert es nicht.",
	boxOfCardsTechStackSectionHeader_00: "Tech-Stack",
	boxOfCardsTechStackSectionDescription_00:
		"Welche Bibliotheken\bhabe ich verwendet?\b" +
		"\nHabe ich Bibliotheken verwendet?" +
		"\nFinden wir es heraus!",
	boxOfCardsTechStackSectionHeader_01: "Web-App",
	boxOfCardsTechStackSectionDescription_01:
		"Der meiste Inhalt befindet\bsich im Client,\bdaher habe ich einige\bFrontend-Bibliotheken verwendet,\bdamit es auch schön aussieht." +
		"\nMaterial Ui als solide Designbasis,\bReact für wiederverwendbare Komponenten\bund Redux für globale States." +
		"\nTypescript, um die\bJavascript-Hölle zu vermeiden,\bWebpack, um den Build kompakter\bzu machen und Docker\bfür einfaches Deployment." +
		"\nImagekit.io ist kompatibel mit React\bund ermöglicht die Auslagerung\bvon Bildern in die Cloud,\bwas die Ladezeit reduziert." +
		"\nDer Server ist nur ein Skript,\bum alles bereitzustellen\bund einige Anfragen\ban die Google Cloud weiterzuleiten.",
	boxOfCardsTechStackSectionHeader_02: "Backend-Dienste",
	boxOfCardsTechStackSectionDescription_02:
		"Diese Dienste haben alle die\bgleichen Kernkomponenten (Core)." +
		"\nDie grünen Boxen sind npm-Pakete,\bdie Github Worfklows aus meinen\banderen Repositories erstellt." +
		"\nAnsonsten verwende ich\bJest für Unit-Tests." +
		"\nDas Broker-Paket lässt diese Dienste\bmit dem RabbitMq-Broker kommunizieren." +
		"\nTypes ermöglicht ein konsistentes\bSenden und Empfangen von Daten." +
		"\nTestData & MockProvider\bsind Dependencies,\bum Redundanz in Unit-Tests\bzu vermeiden." +
		"\nDann gibt es noch CheckAlive,\bdas einfach prüft,\bob ein Spieler noch verbunden ist." +
		"\nUnd mein Wrapper für\bRedis-Interaktion GameRedis.",
	boxOfCardsTechStackSectionHeader_03: "Backend-Pakete",
	boxOfCardsTechStackSectionDescription_03:
		"Das folgende ist der\bTech-Stack meiner Pakete." +
		"\nDer Broker unterstützt RabbitMq\bmit stomp.js über Websockets\bund AMQP über CloudAMQP." +
		"\nGameRedis verwendet mein\bgenerischeres RedisClient-Paket,\bdas Pakete wiederum\bfür die Interaktion\bmit dem Redis-Cluster verwendet." +
		"\nAll die anderen Pakete enthalten nur den Core",
	boxOfCardsTechStackSectionHeader_04: "Google Cloud Functions",
	boxOfCardsTechStackSectionDescription_04:
		"Die Cloud Functions laufen\bauf node.js und verwenden\bein Firebase-Paket zur Interaktion\bmit dem Firestore." +
		"\nDer Firestore speichert Decks\bund Verifizierungslinks sowie einige,\bgrundlegende Accountdaten.",
	boxOfCardsTechStackSectionHeader_05: "RabbitMq",
	boxOfCardsTechStackSectionDescription_05:
		"RabbitMq wird nur mit\bKubernetes-Konfigurationsdateien erstellt\bund verfügt über Plugins,\bdamit Server und Client\bmit dem Broker kommunizieren können.",
	boxOfCardsDeploymentSectionHeader_00: "Deployment",
	boxOfCardsDeploymentSectionDescription_00:
		"So, jetzt haben wir all dieses Kababl,\baber wie bilden wir\bdaraus eine Cloud?" +
		"\nDevOps & Pipelines zur Rettung.",
	boxOfCardsDeploymentSectionHeader_01: "Github Workflow",
	boxOfCardsDeploymentSectionDescription_01:
		"Einfach eine Git-Workflow-Datei\bim Repository anlegen\bund schon können\bwir Jobs definieren." +
		"\nJobs können voneinander abhängig sein,\bCommits auschecken\bund sogar selbst Plugins installieren." +
		"\nEs ist Magie und ich liebe sie!" +
		"\nIch verwende einen Job,\bum die Paketversionen zu erhalten\bund zu prüfen, ob sie im aktuellen\bCommit aktualisiert wurden." +
		"\nFalls ja baue ich die Docker-Images neu." +
		"\nWenn etwas neu gebaut wurde,\blösche ich es aus meinem\bDigital Ocean Cluster\bund deploye neu." +
		"\nJa, mein Workflow loggt sich\bin Digital Ocean ein!" +
		"\nUnd das alles in nur ~300 Zeilen\bKonfiguration (ohne Leerzeilen).",
	boxOfCardsDeploymentSectionHeader_02: "Der Werkzeugkasten",
	boxOfCardsDeploymentSectionDescription_02:
		"Ein guter Freund gab mir\beine nette Vorlage\bfür ein Shell-Skript\bund es machte das Testen\bvon Dingen so viel einfacher." +
		"\nIch habe es mit der Zeit erweitert\bund es erlaubt mir,\bzwischen meinem lokalen Cluster\bund dem Digital Ocean Cluster\bzu wechseln." +
		"\nWenn ich den Github-Workflow\bnicht verwenden möchte,\bum einen Pod zu aktualisieren,\bdann kann ich einfach alle\boder nur einen einzelnen\bDienst redeployen." +
		"\nWenn ich richtig liege,\bdann macht Helm etwas ähnliches,\balso wird das die nächste Sache sein,\bdie ich mir ansehen werde." +
		"\nIch habe auch einen Cluster\bmit Terraform erstellt,\baber es wirkte unnötig für dieses Projekt." +
		"\nJa, es scheint als hätte ich\beine Grenze für Overengineering.",
	boxOfCardsTestsSectionHeader_01: "Unit Tests",
	boxOfCardsTestsSectionDescription_01:
		"Grüne Zahlen auf einem Unit Test\bvermitteln eines der besten Gefühle." +
		"\nIn diesem Projekt gibt es\büber 400 Unit-Tests\bund einige Dutzend Integrationstests." +
		"\nIch muss jedoch noch\beinige logische Branches abdecken\bund der Web-Client hat\büberhaupt keine Unit-Tests.",
	boxOfCardsTestsSectionHeader_02: "Unit Test Output",
	boxOfCardsTestsSectionDescription_02:
		"Dies ist die Ausgabe meines\bTimeoutHandler-Pakets." +
		"\nJa, ich zeige dieses kleinere Paket,\bweil meine größeren Pakete nicht ganz\bso gut abgedeckt sind,\baber psssht!",
	boxOfCardsDesignSectionHeader_00: "Anfänge",
	boxOfCardsDesignSectionDescription_00:
		"In den folgenden Abschnitten\bwerde ich Teile der Pläne\bund Designs posten,\bdie ich auf dem Weg gemacht habe." +
		"\nIch erinnere mich daran,\bwie mein Professor für Softwaredesign\bimmer wieder sagte:\b'Planung ist essentiell.\bPläne sind nutzlos.'",
	boxOfCardsDesignSectionHeader_01: "1. Spielfeld",
	boxOfCardsDesignSectionDescription_01:
		"Ich konzipiere alles in draw.io,\bda ich dieses Tool\bals am wenigsten störend empfinde." +
		"\nUnten links ist meine erste Idee\bfür ein Spielfeld zu sehen." +
		"\nEs war nicht flexibel genug\bfür Auflösungen und Spieloptionen.",
	boxOfCardsDesignSectionHeader_02: "2. Spielfeld",
	boxOfCardsDesignSectionDescription_02:
		"Dieses Layout ist flexibler\bund Rundungen wirken entspannter.",
	boxOfCardsDesignSectionHeader_03: "Seitenwechsel",
	boxOfCardsDesignSectionDescription_03:
		"Mit allem in einem Dokument\bkann ich auch vor Ort\bSeitenübergänge konzipieren.",
	boxOfCardsDesignSectionHeader_04: "Game-loop",
	boxOfCardsDesignSectionDescription_04:
		"Es ist nicht schön,\baber es reichte aus,\bum die Game-States zu erstellen." +
		"\nBeim Spielstart werden\bdie Game-States basierend\bauf den Spieloptionen\baus der Spiellobby verknüpft." +
		"\nZ.B. gibt es für die Auswahl\beines Satzes einen\bGS1GetRandomSentenceGameState\bund einen GS1SelectingSentenceGameState." +
		"\nBeide teilen sich eine\bBasisklasse GetSentenceGameStateBase,\bum Redundanz zu vermeiden.",
	boxOfCardsDesignSectionHeader_05: "UML-Klassendiagramme",
	boxOfCardsDesignSectionDescription_05:
		"Um einen groben\bÜberblick zu bekommen,\bwelche Komponenten benötigt werden,\bbeginne ich gerne\bmit der Erstellung\bvon Klassendiagrammen." +
		"\nEs fühlt sich an wie das Lösen\beines Sodoku, nur dass es\bwirklich spannend ist.",
	boxOfCardsDesignSectionHeader_06: "Sequenzierung",
	boxOfCardsDesignSectionDescription_06:
		"Der Web-Client und die Dienste\bkommunizieren über den Broker\bunter Verwendung von Queues,\bdie sie abonnieren\bund in denen sie veröffentlichen." +
		"\nUm z.B. eine Spiel-Lobby zu erstellen,\bmüssen mehrere Nachrichten\büber verschiedene Queues\bausgetauscht werden.",
	boxOfCardsDesignSectionHeader_07: "Erste Seite",
	boxOfCardsDesignSectionDescription_07:
		"Dies war meine erste Seite,\bdie auf localhost lief." +
		"\nWahnwitzig, wie es einmal aussah.",
	boxOfCardsDesignSectionHeader_08: "Vielleicht ein Scribble-Design verwenden?",
	boxOfCardsDesignSectionDescription_08:
		"Ich habe wirklich versucht,\beinen handgezeichneten Look\bzu erreichen,\baber ein gutes Nutzungs-Feedback\bzu ermöglichen war\beinfach zu schwierig." +
		"\nEs scheint einen Grund\bzu geben, warum Webseiten\bimmer so sauber gestaltet sind",
	boxOfCardsDesignSectionHeader_09: "Designs entwerfen",
	boxOfCardsDesignSectionDescription_09:
		"In Affinity Designer habe ich\bverschiedene Design-Ideen entworfen." +
		"\nAuch wenn einige davon\bsehr schön aussahen,\bwar es schwierig,\balle Komponenten aufeinander abzustimmen.",
	boxOfCardsDesignSectionHeader_10: "Logos entwerfen",
	boxOfCardsDesignSectionDescription_10:
		"Dies war mein zweiter Ansatz\bfür ein Logo." +
		"\nIch schäme mich zu sehr,\bmeine erste Version zu zeigen,\bund selbst diese Hände sind...\baus den Weiten des Internets gestohlen." +
		"\nIch mag es wirklich,\baber es war unmöglich,\bein gut aussehendes Icon daraus zu machen.",
	boxOfCardsDesignSectionHeader_11: "Gestaltung des Logos",
	boxOfCardsDesignSectionDescription_11:
		"Ich habe angefangen,\bmir nebenbei Logo-Design-Videos\banzuschauen und eines gefunden,\bin dem ein Würfel aus Buchstaben\bgestaltet wurde." +
		"\nIch konnte es mit den Buchstaben\bB, O und C anpassen\bund einige Karten hinzufügen." +
		"\nDinge fingen an zu harmonieren.",
	boxOfCardsDesignSectionHeader_12: "Das Spiel designen",
	boxOfCardsDesignSectionDescription_12:
		"Ich begann mich\bdarauf zu konzentrieren,\bdie Benutzeroberfläche\bansprechend zu gestalten. " +
		"\nEine Historie mit fester\bHöhe am oberen Rand,\bHandkarten unten,\bSatz & Info links\bund ein Spielfeld,\bdas den restlichen Platz füllt." +
		"\nIch habe die optionalen Dinge\bwie Chat, Spielerliste\bund Optionen ausblendbar gemacht." +
		"\nEs stellte sich heraus,\bdass diese Komponenten-Header\bein Alptraum im responsive-design waren,\balso habe ich sie in die\bKomponenten verschoben" +
		"\nSie sind daher nur in\bleeren Komponenten dargestellt.",
	boxOfCardsDesignSectionHeader_13: "Schriftarten testen",
	boxOfCardsDesignSectionDescription_13:
		"Ich habe einige Freunde gebeten,\bSchriftarten für diese Anwendung zu testen." +
		"\nSie wählten 4 und 11\bund ich entschied mich\bdann für Nnummer 4.",
	boxOfCardsObstaclesSectionHeader_00: "Wie viele Hindernisse könnte es geben?",
	boxOfCardsObstaclesSectionDescription_00:
		"Nun, ich habe nie gezählt,\baber sagen wir mal,\bes wurde geflucht!" +
		"\nBesondere Erwähnungen:\bScrollbars,\bTabellenimplementierungen,\bÜberprüfung negierter Enums,\bCors,\bCaching,\bCaching,\bGit mit Groß-/Kleinschreibung,\bGoogle Agones,\b" +
		"\nPaketabhängigkeitskonflikte,\bReact-Router v5->v6" +
		"\nSeltsames Verhalten\bvon Cluster-Standorten\bund Github sagt mir,\bdass ich 9500 von 2000\bBuild-Minuten erreicht habe,\bmit einem Guthaben von 56$\bauf meinem 0$-Limit." +
		"\nWar aber ein Bug auf deren Seite~",
	boxOfCardsObstaclesSectionHeader_01: "Umlaute!",
	boxOfCardsObstaclesSectionDescription_01:
		"In der deutschen Sprache\bgibt es Umlaute (ä,ö, etc.)\bund es stellte sich heraus,\bdass die Schriftart,\bdie ich gewählt hatte,\bdiese nicht hatte,\balso...." +
		"\nIch habe sie selbst hinzugefügt!" +
		"\nIch denke, das ist in Ordnung,\bda ich diese Schriftart gekauft habe\bund ansonsten: psssht.",
	boxOfCardsObstaclesSectionHeader_02: "Font Borders",
	boxOfCardsObstaclesSectionDescription_02:
		"Die Schriftart hatte auch\beinige überlagernde Linien\baus... Gründen denke ich." +
		"\nEs war in Ordnung,\bbis ich eine border hinzugefügt habe,\bdie etwas seltsam aussah." +
		"\nAlso habe ich sie herausgearbeitet.\bIch kannte den Font-Editor inzwischen.",
	boxOfCardsObstaclesSectionHeader_03: "Anzeigeeinstellungen",
	boxOfCardsObstaclesSectionDescription_03:
		"Was ist das, fragst du dich?" +
		"\nModerne Kunst?" +
		"\nJa, wahrscheinlich." +
		"\nEs ist auch der Farbunterschied\bzwischen meinen beiden Monitoren,\bund ich war nicht in der Lage,\bsie näher aneinander zu bringen." +
		"\nIch beschloss, dieses Problem zu lösen,\bindem ich mich überhaupt nicht\bdarum kümmerte und weitermachte.",
	boxOfCardsObstaclesSectionHeader_04: "Debugging Unit Tests",
	boxOfCardsObstaclesSectionDescription_04:
		"Nun, einige Fehlermeldungen\bsind zunächst verwirrend\bund dann werden sie unheimlich." +
		"\nIch verwende Typescript,\bso dass die Typen festgelegt sind,\baber bei der Übergabe von Daten\bkann eine Zahl (Number)\bimmer noch ein String sein," +
		"\nobwohl der Compiler denkt,\bdass es eine Zahl ist." +
		"\nNananananananana Javascriiiipt",
	boxOfCardsObstaclesSectionHeader_05: "Wie lange hat diese Reise gedauert?",
	boxOfCardsObstaclesSectionDescription_05:
		"Nun, ich weiß es nicht,\baber alle Repositories zusammen\bhaben 1000+ Commits." +
		"\nIch habe nun ein paar schöne Templates\bund ein paar Sachen,\bdie ich noch aufräumen muss." +
		"\nWas ist noch zu tun? " +
		"\nQuality of Life,\bmehr Unit-Tests,\bLogin mit Google, etc." +
		"\nAh, und ich habe irgendwie vergessen,\beine Benutzerkonto-Verwaltung\bhinzuzufügen...das braucht niemand." +
		"\nIch liebe die Entwicklung einfach <3",

	// Quotes
	quotes:[
/*1*/	"Gib einem Menschen einen Fisch und\ber isst für einen Tag.\nGib einem Menschen ein Fass voller Fisch und\ber isst für eine Woche.",
/*2*/	"Wenn du in den Abgrund schaust\ndann schaut auch der Abgrund\baus dir heraus in dich hinein.",
/*3*/	"Aber weißt du auch warum ich\bCini Minis so liebe?",
/*4*/	"Superkräfte existieren!\nSie sind nur einfach nicht gut.\bWie Sommersprossen oder Allergien.",
/*5*/	"Der Debugger ist\bgenug Dokumentation.",
/*6*/	"Ich mag sehr\benthusiastische Spaziergänge.",
/*7*/	"Ich liebe Software Architektur.",
/*8*/	"Im Hintergrund findet\bkein Krypto-Mining statt.",
/*9*/	"Hast du den\bHerd angelassen?",
/*10*/	"Warum gibt es keinen\bAufstrich mit Brotbelag?",
/*11*/	"Butter, butter ,butter, butter.",
/*12*/	"Sind Feinde die Freunde\bdie wir auf dem Weg\bnicht gefunden haben?",
/*13*/	"Macht Existenz mehr\bSinn als Inexistenz?",
/*14*/	"Was ist nur mit den Tomaten passiert?",
/*15*/	"Menschen sind tendenziell\bweder Kjeld noch Schneemänner",
	],
	quoterPrefix: [
		"",
		"~ ",
		"von ",
	],
	quoter: [
		"Tim Beier",
		"T. Beier",
		"T.B.",
		"Tim B.",
		"by Tim Beier",
		"by T. Beier",
		"by T.B.",
		"by Tim B.",
		"by Tim",
		"Tim",
	],
};

export interface ILanguageTokens extends IBaseStrings {
	formatString: any;
}

export const LanguageTokens: any = new LocalizedStrings(
	{
		en,
		de,
	},
);
export const formatString = LanguageTokens.formatString;

// React
import * as React from "react";
import { Component } from "react";

// Redux
import { IGlobalState } from "../redux/store";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import {Grid, Typography} from "@mui/material";

// Interfaces
import { WTITheme } from "../interfaces/themes/WTTheme";
import { WTIPageProps } from "../interfaces/components/props/WTIPageProps";
import WTImageGrid from "../components/grids/WTImageGrid";
import {projects, team} from "../util/Config";
import WTContainer from "../components/containers/WTContainer";

const styles: any = ((theme: WTITheme) => ({
	root: {
		minHeight: '-webkit-fill-available',
		height: 'auto',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		padding: theme.spacing(4),
	},
	header: {
		padding: theme.spacing(4),
	},
	// Children
	image: {
		[theme.breakpoints.down('sm')]: {
			width: 200,
			height: 200,
		},
		[theme.breakpoints.up('md')]: {
			width: 300,
			height: 300,
		},
		borderRadius: 8,
	},
}));

interface IProps extends WTIPageProps {
}

class About extends Component<IProps> {
	render() {
		const {classes, languageTokens } = this.props;

		return (
			<div className={`${classes.root}`}>
				<div className={classes.section}>
					<Typography
						variant={"h2"}
						className={classes.header}
					>{languageTokens.Team}
					</Typography>

					{
						team(languageTokens)
							.map((containerElement, i) =>
								<WTContainer
									key={`${containerElement.description}${i}`}
									classes={{image: classes.image}}
									section={containerElement}
								/>)
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(About));
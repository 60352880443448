import {BreakpointsOptions} from "@material-ui/core/styles/createBreakpoints";
import {WTIPaletteOptions} from "../interfaces/themes/WTTheme";

export const typography = (palette: WTIPaletteOptions, breakpoints: BreakpointsOptions) => {
	return {
		fonts: {
			monospace: 'Monospace',
		},
		fontFamily: [
			"Monospace",
		].join(","),
		body: {
			[breakpoints.only('xs')]: {
				fontSize: 16,
			},
			[breakpoints.up('sm')]: {
				fontSize: 21,
			},
			zIndex: 1,
			textTransform: "none",
			alignSelf: 'center',
			textAlign: "center",
		},
		body2: {
			[breakpoints.only('xs')]: {
				fontSize: 12,
			},
			[breakpoints.only('sm')]: {
				fontSize: 14,
			},
			[breakpoints.only('md')]: {
				fontSize: 18,
			},
			[breakpoints.up('lg')]: {
				fontSize: 18,
			},
			color: palette.text.primary,
		},
		h1: {
			fontSize: 28,
			color: palette.text.primary,
			alignSelf: 'center',
		},
		h2: {
			color: palette.text.primary,
			fontSize: 30,
		},
		h3: {
			[breakpoints.only('xs')]: {
				fontSize: 16,
			},
			[breakpoints.only('sm')]: {
				fontSize: 22,
			},
			[breakpoints.up('md')]: {
				fontSize: 28,
			},
			zIndex: 1,
			color: palette.text.quaternary,
			fontStyle: "italic",
			alignSelf: 'center',
		},
		h4: {
			[breakpoints.only('xs')]: {
				fontSize: 14,
			},
			color: palette.text.secondary,
			fontSize: 25,
			"&:hover": {

				color: palette.text.tertiary
			},
			"&:active": {

				color: palette.text.tertiary
			},
		},
		h5: {
			[breakpoints.down('sm')]: {
				fontSize: 20,
			},
			[breakpoints.up('md')]: {
				fontSize: 22,
			},
			color: palette.text.primary,

		},
		subtitle1: {
			fontStyle: 'italic',
			color: palette.text.tertiary,
			fontSize: 20,
		},
		subtitle2: {
			[breakpoints.only('xs')]: {
				fontSize: 14,
			},
			[breakpoints.up('sm')]: {
				fontSize: 16,
			},
			zIndex: 1,
			color: palette.text.quaternary,
			fontStyle: "italic",
			alignSelf: 'center',
			paddingTop: 8,
		},
	}
};
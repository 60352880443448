import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {typography} from "./WTTypography";
import {DarkPalette} from "./WTDarkPalette";
import {BrightPalette} from "./WTBrightPalette";

const breakpoints = createBreakpoints({
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	});

export const WTBrightTheme = {
	props: {
		MuiButtonBase: {
			disableRipple: true, // No more ripple
		},
	},
	palette: BrightPalette,
	typography: typography(BrightPalette, breakpoints),
	breakpoints,
};

export const WTDarkTheme = {
	props: {
		MuiButtonBase: {
			disableRipple: true, // No more ripple
		},
	},
	palette: DarkPalette,
	typography: typography(DarkPalette, breakpoints),
	breakpoints,
};

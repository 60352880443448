import {ILanguageTokens} from "../localization/LanguageTokens";
import {WTISection} from "../interfaces/components/sections/WTISection";
import {WTIProjectPage} from "../interfaces/components/pages/projects/WTIProjectPage";
import {WTIOrientation} from "../interfaces/components/utility/WTIOrientation";

export const alertDurationInMs = 4000;
// EmailJs
export const emailJsServiceId = "email-timbeier.com";
export const emailJsTemplateId = "template_6f8l9jq";
export const emailJsUserId = "user_DJx4iMfQn6UFwIhlcJQmt";

export const team = (languageTokens: ILanguageTokens): WTISection[] => {
    return [
        {
            name: languageTokens.teamMember01Name,
            image: "team/tim_01.jpg",
            descriptionHeader: languageTokens.teamMember01Header01,
            description: languageTokens.teamMember01Info01,
            orientation: WTIOrientation.Left,
        },
        {
            descriptionHeader: languageTokens.teamMember01Header02,
            description: languageTokens.teamMember01Info02,
            orientation: WTIOrientation.Center,
        },
        {
            descriptionHeader: languageTokens.teamMember01Header03,
            description: languageTokens.teamMember01Info03,
            orientation: WTIOrientation.Center,
        },
        {
            descriptionHeader: languageTokens.teamMember01Header04,
            description: languageTokens.teamMember01Info04,
            orientation: WTIOrientation.Center,
        },
    ]
};

export const projects = (languageTokens: ILanguageTokens): WTIProjectPage[] => {
    return [
        // boxOfCards
        {
            mediaCardElement: {
                thumbnail: 'projects/web/boxofcards/webapp/01_landingPage.png',
                description: languageTokens.boxOfCardsDescription,
                name: languageTokens.boxOfCards,
                imageAsDescriptionHeader: 'projects/web/boxofcards/webapp/logoTextBig.png',
                route: '/boxofcards',
            },
            sections: [
                {
                    image: 'projects/web/boxofcards/webapp/01_landingPage.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_01,
                    description: languageTokens.boxOfCardsWebappSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/webapp/02_cardTutorial.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_02,
                    description: languageTokens.boxOfCardsWebappSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/webapp/03_deckSelection.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_03,
                    description: languageTokens.boxOfCardsWebappSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/webapp/04_deckEditor.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_04,
                    description: languageTokens.boxOfCardsWebappSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/webapp/05_multiplayerlobby.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_05,
                    description: languageTokens.boxOfCardsWebappSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/webapp/06_gamelobby.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_06,
                    description: languageTokens.boxOfCardsWebappSectionDescription_06,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/webapp/07_game.png',
                    descriptionHeader: languageTokens.boxOfCardsWebappSectionHeader_07,
                    description: languageTokens.boxOfCardsWebappSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                // Architecture
                {
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_00,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_00,
                    orientation: WTIOrientation.Center,
                },
                {
                    image: 'projects/web/boxofcards/architecture/01_componentsAll.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_01,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/architecture/02_componentWebApp.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_02,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/architecture/03_componentsBackend.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_03,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/architecture/04_componentRedis.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_04,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/architecture/05_componentRabbitMq.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_05,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/architecture/06_componentGoogleCloud.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_06,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_06,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/architecture/07_kubectlGetAll.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_07,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/architecture/08_digitalOceanKubernetes.png',
                    descriptionHeader: languageTokens.boxOfCardsArchitectureSectionHeader_08,
                    description: languageTokens.boxOfCardsArchitectureSectionDescription_08,
                    orientation: WTIOrientation.Right,
                },
                // TechStack
                {
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_00,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_00,
                    orientation: WTIOrientation.Center,
                },
                {
                    image: 'projects/web/boxofcards/techstack/01_webclient.png',
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_01,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/techstack/02_backendservices.png',
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_02,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/techstack/03_packages.png',
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_03,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/techstack/04_googlecloudfunctions.png',
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_04,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/techstack/05_rabbitmq.png',
                    descriptionHeader: languageTokens.boxOfCardsTechStackSectionHeader_05,
                    description: languageTokens.boxOfCardsTechStackSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                // Deployment
                {
                    descriptionHeader: languageTokens.boxOfCardsDeploymentSectionHeader_00,
                    description: languageTokens.boxOfCardsDeploymentSectionDescription_00,
                    orientation: WTIOrientation.Center,
                },
                {
                    image: 'projects/web/boxofcards/deployment/01_gitWorkflow.png',
                    descriptionHeader: languageTokens.boxOfCardsDeploymentSectionHeader_01,
                    description: languageTokens.boxOfCardsDeploymentSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/deployment/02_shellScript.png',
                    descriptionHeader: languageTokens.boxOfCardsDeploymentSectionHeader_02,
                    description: languageTokens.boxOfCardsDeploymentSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                // Tests
                {
                    image: 'projects/web/boxofcards/tests/01_gameTests.png',
                    descriptionHeader: languageTokens.boxOfCardsTestsSectionHeader_01,
                    description: languageTokens.boxOfCardsTestsSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/tests/02_timeoutHandler.png',
                    descriptionHeader: languageTokens.boxOfCardsTestsSectionHeader_02,
                    description: languageTokens.boxOfCardsTestsSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                // Design
                {
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_00,
                    description: languageTokens.boxOfCardsDesignSectionDescription_00,
                    orientation: WTIOrientation.Center,
                },

                {
                    image: 'projects/web/boxofcards/design/01_oldDesign1.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_01,
                    description: languageTokens.boxOfCardsDesignSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/design/02_oldDesign2.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_02,
                    description: languageTokens.boxOfCardsDesignSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },

               //{
               //    image: 'projects/web/boxofcards/design/03_pageRouting.png',
               //    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_03,
               //    description: languageTokens.boxOfCardsDesignSectionDescription_03,
               //    orientation: WTIOrientation.Left,
               //},
               //{
               //    image: 'projects/web/boxofcards/design/04_gameLoop.png',
               //    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_04,
               //    description: languageTokens.boxOfCardsDesignSectionDescription_04,
               //    orientation: WTIOrientation.Right,
               //},
               //{
               //    image: 'projects/web/boxofcards/design/05_firstUml.png',
               //    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_05,
               //    description: languageTokens.boxOfCardsDesignSectionDescription_05,
               //    orientation: WTIOrientation.Left,
               //},
               //{
               //    image: 'projects/web/boxofcards/design/06_communicationViaQueues.png',
               //    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_06,
               //    description: languageTokens.boxOfCardsDesignSectionDescription_06,
               //    orientation: WTIOrientation.Right,
               //},

                {
                    image: 'projects/web/boxofcards/design/07_firstLandingPage.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_07,
                    description: languageTokens.boxOfCardsDesignSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/design/08_secondLandingPage.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_08,
                    description: languageTokens.boxOfCardsDesignSectionDescription_08,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/design/09_loginDesigns.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_09,
                    description: languageTokens.boxOfCardsDesignSectionDescription_09,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/design/10_logoAttempt.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_10,
                    description: languageTokens.boxOfCardsDesignSectionDescription_10,
                    orientation: WTIOrientation.Right,
                },

                {
                    image: 'projects/web/boxofcards/design/11_landingPageDesigns.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_11,
                    description: languageTokens.boxOfCardsDesignSectionDescription_11,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/design/12_gameDesign1.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_12,
                    description: languageTokens.boxOfCardsDesignSectionDescription_12,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/design/13_peopleChooseFont.png',
                    descriptionHeader: languageTokens.boxOfCardsDesignSectionHeader_13,
                    description: languageTokens.boxOfCardsDesignSectionDescription_13,
                    orientation: WTIOrientation.Right,
                },
                // Obstacles
                {
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_00,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_00,
                    orientation: WTIOrientation.Center,
                },
                {
                    image: 'projects/web/boxofcards/obstacles/01_fontNoUmlauts.png',
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_01,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },

                {
                    image: 'projects/web/boxofcards/obstacles/02_fontDoesNotLikeBorders.png',
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_02,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/obstacles/03_colorDifferenceOfMyMonitors.png',
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_03,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/boxofcards/obstacles/04_types.png',
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_04,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/boxofcards/obstacles/05_Zeitplanung.png',
                    descriptionHeader: languageTokens.boxOfCardsObstaclesSectionHeader_05,
                    description: languageTokens.boxOfCardsObstaclesSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [
                {
                    prefix: "",
                    name: "boxof.cards",
                    address: "https://www.boxof.cards",
                }
            ],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },

        //homepage3D
        {
            mediaCardElement: {
                thumbnail: 'projects/web/homepage3D/5_Homepage3DPage.jpg',
                description: languageTokens.homepage3DDescription,
                name: languageTokens.homepage3DName,
                route: '/homepage3D',
            },
            sections: [
                {
                    image: 'projects/web/homepage3D/5_Homepage3DPage.jpg',
                    descriptionHeader: languageTokens.homepage3DSectionHeader_01,
                    description: languageTokens.homepage3DSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/homepage3D/2_Homepage3DFront.jpg',
                    descriptionHeader: languageTokens.homepage3DSectionHeader_02,
                    description: languageTokens.homepage3DSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/homepage3D/3_Homepage3DContract1.png',
                    descriptionHeader: languageTokens.homepage3DSectionHeader_03,
                    description: languageTokens.homepage3DSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/web/homepage3D/4_Homepage3DContract2.png',
                    descriptionHeader: languageTokens.homepage3DSectionHeader_04,
                    description: languageTokens.homepage3DSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/web/homepage3D/5_Homepage3DPage.jpg',
                    descriptionHeader: languageTokens.homepage3DSectionHeader_05,
                    description: languageTokens.homepage3DSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [
                {
                    prefix: "",
                    name: "madeofcubes.com",
                    address: "https://www.madeofcubes.com",
                }
            ],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },

        // Bachelor
        {
            mediaCardElement: {
                thumbnail: 'projects/studying/nonEuclideanNavigationVr/7_vr_environment_euclidean.jpg',
                description: languageTokens.bachelorDescription,
                name: languageTokens.bachelorName,
                route: '/nonEuclideanNavigationVr'
            },
            sections: [
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/1_Start.jpg',
                    descriptionHeader: languageTokens.bachelorSectionHeader_01,
                    description: languageTokens.bachelorSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    video: "https://www.youtube.com/embed/3vwfOaQ6KBI",
                    descriptionHeader: languageTokens.bachelorSectionHeader_02,
                    description: languageTokens.bachelorSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/3_hyperbolic_rooms.png',
                    descriptionHeader: languageTokens.bachelorSectionHeader_03,
                    description: languageTokens.bachelorSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/4_impossible_rooms_3Turns.PNG',
                    descriptionHeader: languageTokens.bachelorSectionHeader_04,
                    description: languageTokens.bachelorSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/5_impossible_rooms_advantages.PNG',
                    descriptionHeader: languageTokens.bachelorSectionHeader_05,
                    description: languageTokens.bachelorSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/6_portal.jpg',
                    descriptionHeader: languageTokens.bachelorSectionHeader_06,
                    description: languageTokens.bachelorSectionDescription_06,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/7_vr_environment_euclidean.jpg',
                    descriptionHeader: languageTokens.bachelorSectionHeader_07,
                    description: languageTokens.bachelorSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/8_vr_environment_non_euclidean.jpg',
                    descriptionHeader: languageTokens.bachelorSectionHeader_08,
                    description: languageTokens.bachelorSectionDescription_08,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/studying/nonEuclideanNavigationVr/9_component_view.PNG',
                    descriptionHeader: languageTokens.bachelorSectionHeader_09,
                    description: languageTokens.bachelorSectionDescription_09,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [
                {
                    prefix: `${languageTokens.GoogleDrive}: `,
                    name: languageTokens.bachelorThesis,
                    address: "https://drive.google.com/file/d/12beo2Flke734D_f7icdnEoxpy0tuwh-v/view?usp=sharing",
                }
            ],
            downloads: [
            ],
            creators: [languageTokens.teamMember01Name],
        },
        // Portals
        {
            mediaCardElement: {
                thumbnail: 'projects/prototypes/portals/Portals_Thumbnail.png',
                description: languageTokens.portalGunDescription,
                name: languageTokens.portalGunName,
                route: '/portalGun',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/1L78ir0UVxE',
                    descriptionHeader: languageTokens.portalGunSectionHeader_01,
                    description: languageTokens.portalGunSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },

        // Homepage2D
        {
            mediaCardElement: {
                thumbnail: 'projects/web/homepage2D/01_homepage2d',
                description: languageTokens.homepage2DDescription,
                name: languageTokens.homepage2D,
                route: '/homepage2d',
            },
            sections: [
                {
                    image: "projects/web/homepage2D/01_homepage2d",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_01,
                    description: languageTokens.homepage2DSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/02_homepage2D_frontend",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_02,
                    description: languageTokens.homepage2DSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/03_homepage2D_colorpalettes",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_03,
                    description: languageTokens.homepage2DSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/04_homepage2D_materialui-css",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_04,
                    description: languageTokens.homepage2DSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/05_homepage2D_reduxstore",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_05,
                    description: languageTokens.homepage2DSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/06_homepage2D_contactpage",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_06,
                    description: languageTokens.homepage2DSectionDescription_06,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/07_homepage2D_frontend_dev",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_07,
                    description: languageTokens.homepage2DSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/08_homepage2D_exampleproject",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_08,
                    description: languageTokens.homepage2DSectionDescription_08,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/09_homepage2D_projecttypes",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_09,
                    description: languageTokens.homepage2DSectionDescription_09,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/10_homepage2D_dockerfile",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_10,
                    description: languageTokens.homepage2DSectionDescription_10,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/11_homepage2D_deployment",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_11,
                    description: languageTokens.homepage2DSectionDescription_11,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/web/homepage2D/12_homepage2D_utilities",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_12,
                    description: languageTokens.homepage2DSectionDescription_12,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/web/homepage2D/13_homepage2D_imagekitio",
                    descriptionHeader: languageTokens.homepage2DSectionHeader_13,
                    description: languageTokens.homepage2DSectionDescription_13,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },


        // DinoKing
        {
            mediaCardElement: {
                thumbnail: 'projects/2D/dinoKing/DinoKing.png',
                description: languageTokens.dinoKingThumbnailDescription,
                name: languageTokens.dinoKingName,
                route: '/dinoKing'
            },
            sections: [
                {
                    image: "projects/2D/dinoKing/DinoKing.png",
                    descriptionHeader: languageTokens.Gameplay,
                    description: languageTokens.dinoKingSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/2D/dinoKing/DinoKing_Dinos.gif",
                    descriptionHeader: languageTokens.dinoKingSectionHeader_02,
                    description: languageTokens.dinoKingSectionDescription_02,
                    orientation: WTIOrientation.Right,
                }
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Skyblock
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/skyblock/Skyblock.png',
                description: languageTokens.skyBlockDescription,
                name: languageTokens.skyBlockName,
                route: '/skyblock'
            },
            sections: [
                {
                    image: "projects/3D/skyblock/Skyblock.png",
                    descriptionHeader: languageTokens.skyBlockSectionHeader_01,
                    description: languageTokens.skyBlockSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Corridor
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/corridor/Corridor.jpg',
                description: languageTokens.corridorDescription,
                name: languageTokens.corridorName,
                route: '/corridor'
            },
            sections: [
                {
                    image: "projects/3D/corridor/Corridor.jpg",
                    descriptionHeader: languageTokens.corridorSectionHeader_01,
                    description: languageTokens.corridorSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/3D/corridor/Corridor_Reference.jpg",
                    descriptionHeader: languageTokens.corridorSectionHeader_02,
                    description: languageTokens.corridorSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Door
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/door/Door.jpg',
                description: languageTokens.doorDescription,
                name: languageTokens.doorName,
                route: '/door'
            },
            sections: [
                {
                    image: "projects/3D/door/Door.jpg",
                    descriptionHeader: languageTokens.doorSectionHeader_01,
                    description: languageTokens.doorSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Shield
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/shield/1Shield_Front.jpg',
                description: languageTokens.shieldDescription,
                name: languageTokens.shieldName,
                route: '/shield'
            },
            sections: [
                {
                    image: "projects/3D/shield/1Shield_Front.jpg",
                    descriptionHeader: languageTokens.shieldSectionHeader_01,
                    description: languageTokens.shieldSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/3D/shield/2Shield_Back.jpg",
                    descriptionHeader: languageTokens.shieldSectionHeader_02,
                    description: languageTokens.shieldSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/3D/shield/3Shield_Side.jpg",
                    descriptionHeader: languageTokens.shieldSectionHeader_03,
                    description: languageTokens.shieldSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/3D/shield/4Shield_Reference.jpg",
                    descriptionHeader: languageTokens.shieldSectionHeader_04,
                    description: languageTokens.shieldSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Spider
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/spider/1MechSpider_Front.jpg',
                description: languageTokens.spiderDescription,
                name: languageTokens.spiderName,
                route: '/spider'
            },
            sections: [
                {
                    image: 'projects/3D/spider/1MechSpider_Front.jpg',
                    descriptionHeader: languageTokens.spiderSectionHeader_01,
                    description: languageTokens.spiderSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/3D/spider/2MechSpider_Back.jpg',
                    descriptionHeader: languageTokens.spiderSectionHeader_02,
                    description: languageTokens.spiderSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/3D/spider/3MechSpider_FrontWireframe.jpg',
                    descriptionHeader: languageTokens.spiderSectionHeader_03,
                    description: languageTokens.spiderSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/3D/spider/4MechSpider_BackWireframe.jpg',
                    descriptionHeader: languageTokens.spiderSectionHeader_04,
                    description: languageTokens.spiderSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Staff
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/staff/Staff.jpg',
                description: languageTokens.staffDescription,
                name: languageTokens.staffName,
                route: '/staff'
            },
            sections: [
                {
                    image: "projects/3D/staff/Staff.jpg",
                    descriptionHeader: languageTokens.staffSectionHeader_01,
                    description: languageTokens.staffSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/3D/staff/Staff_Reference.jpg",
                    descriptionHeader: languageTokens.staffSectionHeader_02,
                    description: languageTokens.staffSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // planets
        {
            mediaCardElement: {
                thumbnail: 'projects/3D/planets/planet.jpg',
                description: languageTokens.planetsDescription,
                name: languageTokens.planetsName,
                route: '/planets'
            },
            sections: [
                {
                    image: "projects/3D/planets/planet.jpg",
                    descriptionHeader: languageTokens.planetsSectionHeader_01,
                    description: languageTokens.planetsSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/3D/planets/planets.jpg",
                    descriptionHeader: languageTokens.planetsSectionHeader_02,
                    description: languageTokens.planetsSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // symbolQuest
        {
            mediaCardElement: {
                thumbnail: 'projects/games/symbolQuest/SymbolQuest.png',
                description: languageTokens.symbolQuestDescription,
                name: languageTokens.symbolQuestName,
                route: '/symbolQuest'
            },
            sections: [
                {
                    image: "projects/games/symbolQuest/SymbolQuest.png",
                    descriptionHeader: languageTokens.symbolQuestSectionHeader_01,
                    description: languageTokens.symbolQuestSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    video: "https://www.youtube.com/embed/3V9OpiIuZ_E",
                    descriptionHeader: languageTokens.symbolQuestSectionHeader_02,
                    description: languageTokens.symbolQuestSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        {
            mediaCardElement: {
                thumbnail: 'projects/games/terribleTower/00_TT_Titlescreen.jpg',
                description: languageTokens.terribleTowerDescription,
                name: languageTokens.terribleTowerName,
                route: '/terribleTower'
            },
            sections: [
                {
                    image: "projects/games/terribleTower/00_TT_Titlescreen.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_00,
                    description: languageTokens.terribleTowerSectionDescription_00,
                    orientation: WTIOrientation.Left,
                },
                {
                    video: "https://www.youtube.com/embed/-GFPT3hYTTw",
                    descriptionHeader: languageTokens.terribleTowerSectionHeaderTrailer,
                    description: languageTokens.terribleTowerSectionDescriptionTrailer,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/01_TT_StoneAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_01,
                    description: languageTokens.terribleTowerSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/games/terribleTower/02_TT_RuneAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_02,
                    description: languageTokens.terribleTowerSectionDescription_02,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/03_TT_RomeAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_03,
                    description: languageTokens.terribleTowerSectionDescription_03,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/games/terribleTower/04_TT_MedievalAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_04,
                    description: languageTokens.terribleTowerSectionDescription_04,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/05_TT_IndustrialAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_05,
                    description: languageTokens.terribleTowerSectionDescription_05,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/games/terribleTower/06_TT_Artage.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_06,
                    description: languageTokens.terribleTowerSectionDescription_06,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/07_TT_AtomAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_07,
                    description: languageTokens.terribleTowerSectionDescription_07,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/games/terribleTower/08_TT_Matrixage.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_08,
                    description: languageTokens.terribleTowerSectionDescription_08,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/09_TT_SciFiAge.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_09,
                    description: languageTokens.terribleTowerSectionDescription_09,
                    orientation: WTIOrientation.Left,
                },
                {
                    image: "projects/games/terribleTower/10_TT_Endage.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_10,
                    description: languageTokens.terribleTowerSectionDescription_10,
                    orientation: WTIOrientation.Right,
                },
                {
                    image: "projects/games/terribleTower/11_TT_Menu.jpg",
                    descriptionHeader: languageTokens.terribleTowerSectionHeader_11,
                    description: languageTokens.terribleTowerSectionDescription_11,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // PenAndPaperVr
        {
            mediaCardElement: {
                thumbnail: 'projects/studying/penAndPaperVr/CharSheetPreview.jpg',
                description: languageTokens.penAndPaperVrDescription,
                name: languageTokens.penAndPaperVrName,
                route: '/penAndPaperVr',
            },
            sections: [
                {
                    image: 'projects/studying/penAndPaperVr/CharSheetPreview.jpg',
                    descriptionHeader: languageTokens.penAndPaperVrSectionHeader_01,
                    description: languageTokens.penAndPaperVrSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/penAndPaperVr/CharSheetEdited.jpg',
                    descriptionHeader: languageTokens.penAndPaperVrSectionHeader_02,
                    description: languageTokens.penAndPaperVrSectionDescription_02,
                           orientation: WTIOrientation.Right,
                },
                {
                    image: 'projects/studying/penAndPaperVr/EnvironmentAdded.jpg',
                    descriptionHeader: languageTokens.penAndPaperVrSectionHeader_03,
                    description: languageTokens.penAndPaperVrSectionDescription_03,
                           orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/penAndPaperVr/Cube.jpg',
                    descriptionHeader: languageTokens.penAndPaperVrSectionHeader_04,
                    description: languageTokens.penAndPaperVrSectionDescription_04,
                           orientation: WTIOrientation.Right,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        //MachineLearning
        {
            mediaCardElement: {
                thumbnail: 'projects/studying/machineLearning/CAS_thumbnail.jpg',
                description: languageTokens.machineLearningDescription,
                name: languageTokens.machineLearningName,
                route: '/machineLearning',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/2VLYbAdGenk',
                    descriptionHeader: languageTokens.machineLearningSectionHeader_01,
                    description: languageTokens.machineLearningSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/studying/machineLearning/Regelselektion.jpg',
                    descriptionHeader: languageTokens.machineLearningSectionHeader_02,
                    description: languageTokens.machineLearningSectionDescription_02,
                           orientation: WTIOrientation.Right,
                },
            ],
            links: [
                {
                    prefix: `${languageTokens.GoogleDrive}: `,
                    name: languageTokens.machineLearningPaper,
                    address: "https://drive.google.com/file/d/1GJbggizbFd3ScvkZilPfF9FeT1fJ4E3f/view?usp=sharing",
                }
            ],
            downloads: [
            ],
            creators: [languageTokens.teamMember01Name],
        },
        // Empfängnis
        {
            mediaCardElement: {
                thumbnail: 'projects/books/book_empfaengnis',
                description: languageTokens.empfaengnisDescription,
                name: languageTokens.empfaengnisName,
                route: '/empfaengnis',
            },
            sections: [
                {
                    image: 'projects/books/book_empfaengnis',
                    descriptionHeader: languageTokens.empfaengnisSectionHeader_01,
                    description: languageTokens.empfaengnisSectionDescription_01,
                    orientation: WTIOrientation.Left,
                },
            ],
            links: [
                {
                    prefix: "",
                    name: "amazon",
                    address: "https://www.amazon.de/Empf%C3%A4ngnis-Siegelweltgeschichten-1-Tim-Beier-ebook/dp/B009I4RIQI/",
                }
            ],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        //pimpTheLimb
        {
            mediaCardElement: {
                thumbnail: 'projects/prototypes/pimpTheLimb/PimpTheLimb_Thumbnail.png',
                description: languageTokens.pimpTheLimbDescription,
                name: languageTokens.pimpTheLimbName,
                route: '/pimpTheLimb',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/VP0W-R8-ICI',
                    descriptionHeader: languageTokens.pimpTheLimbSectionHeader_01,
                    description: languageTokens.pimpTheLimbSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
                {
                    image: 'projects/prototypes/pimpTheLimb/v1.png',
                    descriptionHeader: languageTokens.pimpTheLimbSectionHeader_02,
                    description: languageTokens.pimpTheLimbSectionDescription_02,
                           orientation: WTIOrientation.Right,
                    size: {
                        width: 200,
                        height: 200,
                    }
                },
                {
                    image: 'projects/prototypes/pimpTheLimb/v2.png',
                    descriptionHeader: languageTokens.pimpTheLimbSectionHeader_03,
                    description: languageTokens.pimpTheLimbSectionDescription_03,
                           orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // StealthCommander
        {
            mediaCardElement: {
                thumbnail: 'projects/prototypes/stealthCommander/StealthCommander_Thumbnail.png',
                description: languageTokens.stealthCommanderDescription,
                name: languageTokens.stealthCommanderName,
                route: '/curves',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/7ZjyJPMk5Nc',
                    descriptionHeader: languageTokens.stealthCommanderSectionHeader_01,
                    description: languageTokens.stealthCommanderSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // GrimRepair
        {
            mediaCardElement: {
                thumbnail: 'projects/gameJams/grimRepair/GrimRepair_Thumbnail.png',
                description: languageTokens.grimRepairDescription,
                name: languageTokens.grimRepairName,
                route: '/grimRepair',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/m4lXb1T_D8o',
                    descriptionHeader: languageTokens.grimRepairSectionHeader_01,
                    description: languageTokens.grimRepairSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },
        // Catastrophic
        {
            mediaCardElement: {
                thumbnail: 'projects/gameJams/catastrophic/Catastrophic_Thumbnail.jpg',
                description: languageTokens.catastrophicDescription,
                name: languageTokens.catastrophicName,
                route: '/catastrophic',
            },
            sections: [
                {
                    video: 'https://www.youtube.com/embed/MXm_HQxDS1w',
                    descriptionHeader: languageTokens.catastrophicSectionHeader_01,
                    description: languageTokens.catastrophicSectionDescription_01,
                           orientation: WTIOrientation.Left,
                },
            ],
            links: [],
            downloads: [],
            creators: [languageTokens.teamMember01Name],
        },

    ];
}
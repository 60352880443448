// Redux
import { SET_LANGUAGE, } from "../types";

// Localization
import { ILanguageTokens } from "../../localization/LanguageTokens";
import { getLanguageFromStorage, getLanguageTokenFromStorage, getMyStrings, updateLanguage, } from "../../localization/Localization";

export interface ILanguageState {
	languageToken: string;
	activeLanguage: string;
	availableLanguages: string[];
	languageTokens: ILanguageTokens;
}

const initialState: ILanguageState = {
	languageToken: getLanguageTokenFromStorage(),
	activeLanguage: getLanguageFromStorage(),
	availableLanguages: [
		"german",
		"english",
	],
	languageTokens: getMyStrings()
};

interface ILanguageAction {
	type: string,
	language: string;
}

export default function(state = initialState, action: ILanguageAction) {
	const { language } = action;

	switch (action.type) {
		case SET_LANGUAGE:
			updateLanguage(language);
			return {
				...state,
				languageToken: language === "english" ? "en" : "de",
				activeLanguage: language,
				languageTokens: getMyStrings(),
			};
		default:
			return state;
	}
}
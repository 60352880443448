// React
import React, {Component} from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import {WTIComponentProps} from "../../interfaces/components/props/WTIComponentProps";
import {IGlobalState} from "../../redux/store";
import {connect} from "react-redux";
import {WTITheme} from "../../interfaces/themes/WTTheme";
import {ButtonBase, Grid, Typography} from "@mui/material";
import {WTISection} from "../../interfaces/components/sections/WTISection";
import {ReplaceSpaces} from "../../util/StringUtility";
import {ILanguageTokens} from "../../localization/LanguageTokens";
import {setOverlayImage} from "../../redux/overlay/overlayActions";
import {WTIOrientation} from "../../interfaces/components/utility/WTIOrientation";

// Images
import {IKImage} from 'imagekitio-react';

const styles: any = ((theme: WTITheme) => ({
    centered: {
        display: 'table', /* keep the background color wrapped tight */
        margin: `${theme.spacing(2)} 0`,
        padding: `${theme.spacing(2)} 0`,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(2),
    },
    container: {
        placeContent: 'center',
        display: 'flex',
        margin: `${theme.spacing(2)} 0`,
        padding: `${theme.spacing(2)} 0`,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(2),
    },
    centerContainer: {
        placeContent: 'center',
        display: 'flex',
        margin: `${theme.spacing(2)} 0`,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
         padding: `${theme.spacing(2)}`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(2)} 0`,
        },
    },
    imageContainerLeft: {
        [theme.breakpoints.only('xs')]: {
            paddingRight: 16,
        },
        paddingLeft: 16,
    },
    imageContainerRight: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 16,
        },
        paddingRight: 16,
    },
    imageContainerCenter: {
        textAlign: 'center',
    },
    image: {
        maxHeight: 300,
        borderRadius: 8,
        imageRendering: '-webkit-optimize-contrast',
    },
    textBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    descriptionHeader: {
        padding: `${theme.spacing(1)} 0`,
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: `16px ${theme.spacing(2)}`,
    },
    descriptionText: {
        whiteSpace: 'pre-line',
    }
}));


interface IProps extends WTIComponentProps {
    languageTokens: ILanguageTokens,
    section: WTISection,
    setOverlayImage: (imageActive: boolean, imagePath: string) => void,
}

interface IState {
    isSmallScreen: boolean;
}
class WTContainer extends Component<IProps> {

    state: IState = {
        isSmallScreen: false,
    }

    render() {
        return this.getContainer();
    };

    componentDidMount() {
        this.checkIsSmallScreen();
        window.addEventListener('resize', this.checkIsSmallScreen)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkIsSmallScreen);
    }

    checkIsSmallScreen = () => {
        const {isSmallScreen} = this.state;
        const isSmallScreenNow = window.screen.width < 600;

        if(isSmallScreen === isSmallScreenNow){
            return;
        }

        this.setState({isSmallScreen: isSmallScreenNow});
    }

    getContainer = () => {
        let { section } = this.props;
        const {isSmallScreen} = this.state;

        if(isSmallScreen){
            return this.centerContainer(section);
        }
        if(section.orientation === WTIOrientation.Left){
            return this.leftContainer(section);
        }
        if(section.orientation === WTIOrientation.Right){
            return this.rightContainer(section);
        }

        return this.centerContainer(section);
    }

    leftContainer = (section: WTISection) => {
        const {classes} = this.props;
        return <Grid container className={classes.container}>
                {this.imageContainer(section, classes.imageContainerLeft)}
                {this.textBoxContainer(section)}
            </Grid>
    }


    rightContainer = (section: WTISection) => {
        const {classes} = this.props;
        return <Grid container className={classes.container}>
            {this.textBoxContainer(section)}
            {this.imageContainer(section, classes.imageContainerRight)}
        </Grid>
    }

    centerContainer = (section: WTISection) => {
        const {classes} = this.props;
        const style =  section.orientation === WTIOrientation.Center ? {} :{};

        return <Grid container className={classes.centerContainer} style={style}>
            {this.imageContainer(section, classes.imageContainerCenter)}
            {this.textBoxContainer(section)}
        </Grid>
    }

    imageContainer = (section: WTISection, className: string) => {
        if(!section.image && !section.video){
            return null;
        }
        return <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            className={className}
        >
            {section.video ? this.video(section) : null}
            {section.image ? this.image(section) : null}
            {section.imageName ?
                <Typography variant={"body2"}>
                    {section.imageName}
                </Typography>
                : null
            }
        </Grid>
    }

    image = (containerElement: WTISection) => {
        const {classes, setOverlayImage} = this.props;
        const style =  containerElement.size ? {
            maxHeight: containerElement.size.height,
            maxWidth: containerElement.size.width,
        } :{};

        return <ButtonBase
                onClick={() => setOverlayImage( true, containerElement.image)}
            >
            <IKImage
                path={containerElement.image}
                loading="lazy"
                lqip={{ active: true }}

                alt={containerElement.image}
                src={containerElement.image}
                style={style}
                className={classes.image}
                onClick={() => setOverlayImage( true, containerElement.image)}
            />
        </ButtonBase>
    }

    video = (containerElement: WTISection) => {
        const {classes} = this.props;

        return <iframe
            className={classes.image}
            width="560"
            height="315"
            src={containerElement.video}
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    }

    textBoxContainer = (containerElement: WTISection) => {
        const {classes} = this.props;
        const description: string = ReplaceSpaces(containerElement.description);

        return <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            className={containerElement.orientation === WTIOrientation.Center ?  classes.centered : ''}
        >
            <Grid className={classes.textBox}>
                <Typography variant={"h3"} className={classes.descriptionHeader}>
                    {containerElement.descriptionHeader}
                </Typography>
                <div className={classes.description}>
                    <Typography
                        className={classes.descriptionText}
                        variant={"body2"}
                    >{description}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    }

}

const mapStateToProps = (state: IGlobalState) => ({
    languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
    setOverlayImage,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTContainer));
// Redux
import {SET_OVERLAY_IMAGE} from "../types";

export interface IOverlayState {
	imageActive: boolean;
	imagePath: string;
}

const initialState: IOverlayState = {
	imageActive: false,
	imagePath: null,
};

interface ISnackbarAction extends IOverlayState {
	type: string,
}

export default function(state: IOverlayState = initialState, action: ISnackbarAction) {
	const {imageActive, imagePath} = action;

	switch (action.type) {
		case SET_OVERLAY_IMAGE:
			return {
				...state,
				imageActive: imageActive,
				imagePath: imagePath,
			};
		default:
			return state;
	}
}
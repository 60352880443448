import {WTIPaletteOptions} from "../interfaces/themes/WTTheme";

export const DarkPalette: WTIPaletteOptions = {
	primary: {
		main: '#ff4f4f',
		light: '#ffa270',
		dark: '#c63f17',
		contrastText: "#222222",
	},
	secondary: {
		main: '#333333',
		light: '#D800FF',
		dark: '#D800FF',
		contrastText: "#D800FF"
	},
	tertiary: {
		main: '#222222',
		light: '#D800FF',
		dark: '#D800FF',
		border: "#D800FF",
		contrastText: "#D800FF",
	},
	text: {
		primary: '#DDDDDD',
		secondary: '#FFFFFF',
		tertiary: '#DDDDDD',
		quaternary: '#BBBBBB',
		disabled: '#D800FF',
		hint: '#D800FF',
	},
};
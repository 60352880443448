// Redux
import {SET_OVERLAY_IMAGE} from "../types";
import { Dispatch } from "redux";

export const setOverlayImage = (imageActive: boolean, imagePath: string) => (dispatch: Dispatch) => (
	dispatch({
		type: SET_OVERLAY_IMAGE,
		imageActive: imageActive,
		imagePath: imagePath,
	})
);
// React
import React, {Component} from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import {WTITheme} from "../../interfaces/themes/WTTheme";
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {Grid, Typography} from "@mui/material";
import {WTIFooterElement} from "../../interfaces/components/footers/WTIFooterElement";
import WTHistory from "../../WTHistory";
import WTButton from "../buttons/WTButton";

const styles: any = ((theme: WTITheme) => ({
	root: {
		// position: 'absolute',
		// bottom: 0,
		// width: '100%',
		backgroundColor: theme.palette.primary.main,
		flex: "0 1 30px",
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	// todo move
	text: {
		height: 20,
		fontSize: 12,
		color: theme.palette.text.secondary,
	}
}));


interface IProps extends WTIComponentProps {
	footerElements: WTIFooterElement[],
}

class WTFooter extends Component<IProps> {

	render() {
		let { classes, footerElements } = this.props;

		return (
			<Grid item className={classes.root} xs={12} sm={12}>
				{footerElements.map((f, i) =>
					<WTButton
						key={f.name + i}
						textButton
						classes={{root: classes.text}}
						onClick={() => WTHistory.push(f.link)}
					>
						<Typography variant={"h4"}>
							{f.name}
						</Typography>
					</WTButton>
				)}
			</Grid>
		);
	};
}

export default (withStyles(styles)(WTFooter));
// Css
import "./App.css";

// React
import * as React from "react";
import {Switch, Route, HashRouter} from "react-router-dom";
import {useState} from "react";
import WTHistory from "./WTHistory";

// Redux
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { ConnectedRouter } from "react-router-redux";

// Material Ui
import makeStyles from "@mui/styles/makeStyles";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material";

//Components
import WTNavBar from "./components/navigation/WTNavBar";
import WTLoadingOverlay from "./components/loading/WTLoadingOverlay";
import WTAlert from "./components/alert/WTAlert";
import WTFooter from "./components/footers/WTFooter";
import {WTIFooterElement} from "./interfaces/components/footers/WTIFooterElement";

// Routes
import { routes } from "./routing/Routes";

//Pages
import home from "./pages/home";
import about from "./pages/about";
import contact from "./pages/contact";
import legalNotice from "./pages/legalNotice";

// Interfaces
import {ILanguageTokens} from "./localization/LanguageTokens";
import {WTDarkTheme, WTBrightTheme} from "./theme/WTTheme";
import {projects} from "./util/Config";
import ProjectPage from "./pages/projectPage";
import WTOverlayImage from "./components/overlay/WTOverlayImage";

// Images
import { IKContext } from 'imagekitio-react'

//Styles for App component
export const styles: any = makeStyles((theme: Theme) => ({
	appContainer: {
	    display: 'flex',
        flexDirection: 'column',
	    height: 'fit-content',
        flex: "1 1 auto",
		justifyContent: 'center',
		// overflow: 'auto',
        margin: 0,
	},
    bright: {
        backgroundImage: "linear-gradient(to bottom, #fff6f0, #fff6e7, #fff5e3, #fff3e0, #fff2dc, #ffeddf, #ffeae8, #ffe9f4, #ffeaff)",
    },
    dark: {
	    backgroundImage: 'linear-gradient(to bottom, #222222, #111111)'
    },
}));
const myStore = configureStore(history);

// todo react helmet header tags
// todo avoid starting at wrong page position when going back (scroll pos)
// todo security xss
// ToDo button types
// todo test on ios, edge, safari
// todo SEO homepage 3D
// todo ksp
// todo add homepage itself as project

// Optional
// Css Animations: blob button: https://webdeasy.de/en/top-css-buttons-en/ &&  Button bubble effect && Blobs button
//     text animation  https://codepen.io/cesar2535/pen/ByYXpN
function App() {

    // localStorage.clear();
	const classes = styles();
	const store = myStore.getState();
	const languageTokens: ILanguageTokens = store.language.languageTokens;

    const footerElements: WTIFooterElement[] = [
        {
            name: languageTokens.LegalNotice,
            link: routes.legalNotice,
        },
        {
            name: languageTokens.Contact,
            link: routes.contact,
        },
    ];
    const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches || store.theme.isDark;
    const [dark, setDark] = useState<boolean>(isDark);

	return (
        <Provider store={myStore}>
			<ConnectedRouter history={WTHistory} store={myStore}>
				<HashRouter  >
					<StyledEngineProvider injectFirst>
                        <ThemeProvider theme={dark ? createTheme(WTDarkTheme) : createTheme(WTBrightTheme)}>
                            <IKContext
                                urlEndpoint="https://ik.imagekit.io/timfehrmann/homepage/"  // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
                                >
                            <WTNavBar dark={dark} setIsDark={ (dark: boolean) => setDark(dark) }/>
                            <WTAlert/>
                            <WTLoadingOverlay/>
                            <WTOverlayImage/>
                            <div className={`${classes.appContainer} ${ dark ? classes.dark : classes.bright} height-moz-available pane scroller`}>
                                <Switch>
                                    <Route exact path={routes.about} component={about}/>
                                    <Route exact path={routes.contact} component={contact}/>
                                    <Route exact path={routes.legalNotice} component={legalNotice}/>
                                    {projects(languageTokens).map((p, index) =>
                                        <Route
                                            exact
                                            key={p.mediaCardElement.name}
                                            path={p.mediaCardElement.route}
                                            // Passing index to project to allow for localization without page reload in projectPage
                                            component={(props) =>
                                                <ProjectPage {...props} projectIndex={index}/>
                                            }
                                        />)
                                    }
                                    <Route path={routes.index} component={home}/>
                                </Switch>
                            <WTFooter footerElements={footerElements} />
                            </div>
                            </IKContext>
                        </ThemeProvider>
                    </StyledEngineProvider>
				</HashRouter>
			</ConnectedRouter>
		</Provider>
    );
}

export default App
// React
import React, { Component } from "react";

import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Typography } from "@mui/material";

// Redux
import { IGlobalState } from "../../redux/store";
import { connect } from "react-redux";

// Interfaces
import {WTITheme} from "../../interfaces/themes/WTTheme";
import { ILanguageTokens } from "../../localization/LanguageTokens";
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";

const styles: any = ((theme: WTITheme) => ({
	root: {
		position: 'fixed',
		zIndex: 5000,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',

		textAlign: 'center',
	},
	loadingContainer: {
		zIndex: 5001,
		position: 'relative',
		top: '40%'
	},
	text: {
		fontSize: 30,
		color: '#FFFFFF',
		position: 'relative',
	}
}));

interface IProps extends WTIComponentProps {
	loading: boolean,
	message?: string;
	languageTokens: ILanguageTokens,
}

class WTLoadingOverlay extends Component<IProps> {

	render() {
		const { loading, message, languageTokens, classes } = this.props;

		 if(!loading) {
			 return null;
		 }

		 const text = !!message ? message : languageTokens.loading;

		return (
			<div className={classes.root}>
				<div className={classes.loadingContainer}>
					<CircularProgress  size={50} />
					<Typography className={classes.text}>
						{text}
					</Typography>
				</div>
			</div>
		);
	};
}

const mapStateToProps = (state: IGlobalState) => ({
	loading: state.ui.loading,
	languageTokens: state.language.languageTokens,
});


export default connect(mapStateToProps)(withStyles(styles)(WTLoadingOverlay));
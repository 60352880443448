// React
import React, { SyntheticEvent } from "react";

// Redux
import { IGlobalState } from "../../redux/store";
import { connect } from "react-redux";
import { IAlertState } from "../../redux/alert/alertReducer";
import { setAlert } from "../../redux/alert/alertActions";

// Material Ui
import Snackbar from "@mui/material/Snackbar";
import { Alert } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {WTITheme} from "../../interfaces/themes/WTTheme";

// Config
import { alertDurationInMs } from "../../util/Config";

const styles: any = () => withStyles((theme: WTITheme) => ({
	root: {
		width: "100%",
	},
	myTypography: {
		fontSize: 18,
		textTransform: 'none',
	}
}));

interface IProps extends WTIComponentProps {
	alert: IAlertState,
	dispatchAlert: (snackBarOpen: boolean, snackBarType: string, snackBarText: string) => void
}

const CustomizedAlert = (props: IProps) => {
	const { alert: {alertOpen, alertType, alertText}, dispatchAlert, classes } = props;

	// Do not display empty sentence
	if(alertText === null || alertText === "")
		return null;

	// Do not close Snackbar if click happens somewhere else
	const handleAutoClose = (event: SyntheticEvent, reason: string) => {
		if(reason === "clickaway") {
			return;
		}
		dispatchAlert(false, alertType, alertText);
	};

	return (
		<Snackbar
			className={classes.root}
			open={alertOpen}
			autoHideDuration={alertDurationInMs}
			onClose={handleAutoClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
			style={{pointerEvents: 'none'}} // Does not work via class
		>

			<Alert
				severity={alertType}
				onClose={(event) => handleAutoClose(event, "closed")}
			>
				<Typography className={classes.myTypography}>{alertText}</Typography>
			</Alert>
		</Snackbar>
	);
};

const mapStateToProps = (state: IGlobalState) => ({
	alert: state.alert
});

const mapActionsToProps = {
	dispatchAlert: setAlert
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CustomizedAlert));
export const InsertString = (a: string, b: string, index: number): string => {
	return [a.slice(0, index), b, a.slice(index)].join('');
};
export const ReplaceSpaces = (text: string): string => {
	text = replaceAll(text, ' ', '\u00A0');
	text = replaceAll(text, '\b', ' ');
	text = replaceAll(text, '-', '\u2011')
	return text;
}

export const replaceAll = (str, find, replace) => {
	return str.replace(new RegExp(find, 'g'), replace);
}

export const createSeed = (): number => {
	return Math.floor(Math.random()* Number.MAX_SAFE_INTEGER - 1);
}
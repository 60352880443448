// React
import * as React from "react";
import * as ReactDOM from "react-dom";

// App
import App from "./App";

ReactDOM.render(
	<App/>,
	document.getElementById("root"),
);

// Redux
import { SET_ALERT } from "../types";
import { Dispatch } from "redux";

export const setAlert = (alertOpen: boolean, alertType: string, alertText: string) => (dispatch: Dispatch) => (
	dispatch({
		type: SET_ALERT,
		alertOpen,
		alertType,
		alertText,
	})
);
// React
import React, { Component, ReactNode } from "react";

import withStyles from '@mui/styles/withStyles';
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// Localization
import { localize } from "../../localization/Localization";

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {WTITheme} from "../../interfaces/themes/WTTheme";

// Images
import { ILanguageTokens } from "../../localization/LanguageTokens";
import { IGlobalState } from "../../redux/store";
import { connect } from "react-redux";

const styles: any = ((theme: WTITheme) => ({
	root: {
		width: 'fit-content',
	},
	select: {
		color: theme.palette.text.secondary,
		"&.Mui-focused": {
			color: theme.palette.text.secondary,
		},
		"&:after": {
			borderBottomColor: theme.palette.text.secondary,
		},
		"& .MuiSvgIcon-root": {
			color: theme.palette.text.secondary,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent !important",
		},

	},
	menuItem: {
		color: '#222222',
	}
}));

interface IProps extends WTIComponentProps {
	value: string;
	texts: string[];
	onSelect: (val: string) => void;
	localizeTexts?: boolean;
	languageTokens: ILanguageTokens;
}

class WTSelect extends Component<IProps> {

	render() {
		const { classes, value, onSelect, texts, localizeTexts  } = this.props;
		return (
			<Box className={classes.root}>
				<FormControl fullWidth size={"small"}>
					<Select
						className={classes.select}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left"
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left"
							},

						}}

						displayEmpty
						labelId="select-label"
						id="select"
						value={value}
						onChange={(event)  => onSelect(event.target.value.toString())}
					>
						{
							texts.map(text =>
								<MenuItem
									className={classes.menuItem}
									key={text}
									value={text}
								>
									{localizeTexts ? localize(text) : text}
								</MenuItem>
							)
						}
					</Select>
				</FormControl>
			</Box>
		);
	};

}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
};


export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTSelect));
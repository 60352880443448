export interface IRoutes {
	index: string;
	about: string;
	contact: string;
	legalNotice: string;
}

export const routes: IRoutes = {
	index: "/",
	about: "/about",
	contact: '/contact',
	legalNotice: '/legalNotice'
};
// Redux
import { SET_ALERT } from "../types";

export interface IAlertState {
	alertOpen: boolean;
	alertType: 'success' | 'info' | 'warning' | 'error';
	alertText: string;
}

const initialState: IAlertState = {
	alertOpen: false,
	alertType: "success",
	alertText: "",
};

interface ISnackbarAction extends IAlertState {
	type: string,
}

export default function(state: IAlertState = initialState, action: ISnackbarAction) {
	const {alertOpen, alertType, alertText} = action;

	switch (action.type) {
		case SET_ALERT:
			return {
				...state,
				alertOpen: alertOpen,
				alertType: alertType,
				alertText: alertText
			};
		default:
			return state;
	}
}
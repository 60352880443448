// Redux
import { LOADING_UI, } from "../types";

export interface IUiState {
	loading: boolean,
	message?: string,
}

const initialState: IUiState = {
	loading: false,
};

interface IUiAction extends IUiState {
	type: string,
}

export default function(state = initialState, action: IUiAction) {
	const {type, loading, message} = action;
	switch (type) {
		case LOADING_UI:
			return {
				...state,
				loading,
				message,
			};
		default:
			return state;
	}
}
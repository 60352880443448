// React
import React, {Component} from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";

import {IGlobalState} from "../../redux/store";
import {connect} from "react-redux";
import {WTITheme} from "../../interfaces/themes/WTTheme";
import {ButtonBase, Grid, Typography} from "@mui/material";
import {WTISection} from "../../interfaces/components/sections/WTISection";
import {ReplaceSpaces} from "../../util/StringUtility";
import {ILanguageTokens} from "../../localization/LanguageTokens";
import {setOverlayImage} from "../../redux/overlay/overlayActions";

// Images
import {IKImage} from 'imagekitio-react';

const styles: any = ((theme: WTITheme) => ({
    root: {
        position: 'fixed',
        zIndex: 5000,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',

        textAlign: 'center',
    },
    container: {
        zIndex: 5001,
        position: 'relative',
        top: '2.5%'
    },
    image: {
        borderRadius: 8,
        maxWidth: 900,
    },
}));


interface IProps extends WTIComponentProps {
    languageTokens: ILanguageTokens,
    imageActive: boolean,
    imagePath: string,
    setOverlayImage: (imageActive: boolean, imagePath: string) => void,
}

class WTOverlayImage extends Component<IProps> {

    render() {
        let { imageActive, imagePath, setOverlayImage, classes } = this.props;

        if(!imageActive) {
            return null;
        }

        return <div
            className={classes.root}
            onClick={() => {setOverlayImage(false, "");}}>
            <div className={classes.container}>
                <IKImage
                    path={imagePath}
                    loading="lazy"
                    lqip={{ active: true }}

                    alt={imagePath}
                    src={imagePath}
                    className={classes.image}
                    onClick={() => setOverlayImage( true, imagePath)}
                />
            </div>
        </div>
    };
}

const mapStateToProps = (state: IGlobalState) => (
    {
    languageTokens: state.language.languageTokens,
    imageActive: state.overlay.imageActive,
    imagePath: state.overlay.imagePath,
});

const mapActionsToProps = {
    setOverlayImage,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTOverlayImage));
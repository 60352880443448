// React
import React, {Component, ReactNode} from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import {WTITheme} from "../../interfaces/themes/WTTheme";
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {Grid} from "@mui/material";
import {WTIMediaCardElement} from "../../interfaces/components/cards/WTIMediaCardElement";
import WTMediaCard from "../cards/WTMediaCard";
import {WTIProjectPage} from "../../interfaces/components/pages/projects/WTIProjectPage";
import {connect} from "react-redux";
import {IGlobalState} from "../../redux/store";


const styles: any = ((theme: WTITheme) => ({
	root: {
	},
	gridList: {
		width: 'auto',
		placeContent: 'center',
		margin: 'auto',
	},
	image: {
		height: 200,
	}
}));


interface IProps extends WTIComponentProps {
	projectPages: WTIProjectPage[],
	isDark: boolean;
}

interface IState {
	wasDark: boolean;
}

class WTImageGrid extends Component<IProps> {

	state: IState = {
		wasDark: this.props.isDark
	}

	shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
		if(! this.state){
			this.setState({	wasDark: nextProps.isDark})
			return true;
		}

		if(this.state.wasDark === nextProps.isDark){
			return false;
		}

		this.state.wasDark = nextProps.isDark;
		return true;
	}

	render() {
		const {classes, projectPages } = this.props;

		return (
			<div className={classes.root}>
				<Grid container gap={1}  className={classes.gridList}>
					{projectPages.map((project, index) => (
						<WTMediaCard
							key={`${project.mediaCardElement.name}_${index}`}
							mediaCardElement={project.mediaCardElement}
						/>
					))}
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: IGlobalState) => ({
	isDark: state.theme.isDark,
});

const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTImageGrid));
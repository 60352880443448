import { LanguageTokens } from "./LanguageTokens";
import { setLanguage } from "../redux/language/languageActions";

let activeLanguageToken: string;
export const localize = (token: string) => {
	// @ts-ignore
	if(!isNaN(token)) {
		return '' + token;
	}
	return getMyStrings()[token];
};


export const getMyStrings = () => {
	// @ts-ignore Accessing internal properties to get localized sentence using tokens
	return LanguageTokens._props[getLanguageToken()];
};

export const getLanguageToken = () => {
	if(!activeLanguageToken || activeLanguageToken === 'undefined'){
		activeLanguageToken = getLanguageTokenFromStorage();
	}
	return activeLanguageToken;
};

export const setLanguageFromStorage = () => {
	let language = localStorage.getItem('language');
	if(!language || language === 'undefined'){
		language = 'english';
	}
	setLanguage(language);
};

export const getLanguageFromStorage = (): string => {
	let language = localStorage.getItem('language');
	if(!language || language === 'undefined'){
		language = 'english';
	}
	return language;
};

export const getLanguageTokenFromStorage = (): string => {
	const language: string = getLanguageFromStorage();
	return languageTokenByLanguage(language)
};

export const updateLanguage = (language: string) => {
	localStorage.setItem('language', language);
	activeLanguageToken = languageTokenByLanguage(language);
	LanguageTokens.setLanguage(activeLanguageToken);
};

const languageTokenByLanguage = (language: string) => {
	switch (language) {
		case "english":
			return "en";
		case "german":
			return "de";
		default:
			return "en";
	}
};
//UI reducer types
export const LOADING_UI = 'LOADING_UI';

// Overlay types
export const SET_OVERLAY_IMAGE = 'SET_OVERLAY_IMAGE';

// Snackbar types
export const SET_ALERT = 'SET_ALERT';

// Snackbar types
export const SET_NEW_SEEDS = 'SET_SEED';

// Language types
export const SET_LANGUAGE = 'SET_LANGUAGE';

// Language types
export const SET_THEME = 'SET_THEME';
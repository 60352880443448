// React
import * as React from "react";
import {Component, useRef} from "react";

// Redux
import { IGlobalState } from "../redux/store";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import {Button, CardContent, Grid, TextField, Typography, Card, Theme} from "@mui/material";

// Interfaces
import { WTIPageProps } from "../interfaces/components/props/WTIPageProps";
import {ILanguageTokens} from "../localization/LanguageTokens";

// EmailJs
import emailjs from "emailjs-com";
import {setAlert} from "../redux/alert/alertActions";
import {emailJsServiceId, emailJsTemplateId, emailJsUserId} from "../util/Config";
import {WTITheme} from "../interfaces/themes/WTTheme";

const styles: any = ((theme: WTITheme) => ({
	root: {
		minHeight: 'inherit',
		height: 'auto',
		flex: '1 1',
	},
	card: {
		maxWidth: 450,
		padding: "20px 5px",
		margin: `${theme.spacing(8)} auto`,
		backgroundColor: theme.palette.secondary.main,
	},
	header: {
		textAlign: 'center',
	},
	info: {
		textAlign: 'center',
		color: theme.palette.text.primary,
		paddingBottom: theme.spacing(1),
	},
	cardContent: {
	},
	form: {

	},
	labelInput: {

		"&.Mui-focused": {
			color: theme.palette.text.primary,
		},
		backgroundColor: theme.palette.tertiary.main,//theme.palette.secondary.main,
		color: theme.palette.text.primary,
	},
	input: {

		backgroundColor: theme.palette.tertiary.main,//theme.palette.secondary.main,
		color: theme.palette.text.primary,
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.secondary
	}
}));

interface IProps extends WTIPageProps {
	setAlert: (alertOpen: boolean, alertType: string, alertText: string) => void,
}

interface IState {
	firstName: string;
	lastName: string;
	email: string;
	subject: string;
	message: string;
}
class Contact extends Component<IProps> {

	initialState: IState = {
		firstName: "",
		lastName: "",
		email: "",
		subject: "",
		message: "",
	}

	state: IState = {...this.initialState};

	sendEmail = (e) => {
		e.preventDefault();

		const {setAlert, languageTokens} = this.props;

		emailjs.send(emailJsServiceId, emailJsTemplateId, {...this.state}, emailJsUserId)
			.then((result) => {
				console.log(result.text);
				setAlert(true, 'success', languageTokens.sendingEmailSuccessful);
				this.setState(this.initialState);
			}, (error) => {
				setAlert(true, 'error', error.text);
				console.log(error.text);
			});
	}

	handleChange(event) {
		const {name, value} = event.target;
		this.setState({[name]: value})
	}

	render() {
		const {classes, languageTokens } = this.props;

		return (
			<div className={`${classes.root}`}>
				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Typography
							className={classes.header}
							gutterBottom
							variant="h2"
						>{languageTokens.Contact}</Typography>
						<Typography
							className={classes.info}
							variant="body2"
							color="textSecondary"
							component="p"
							gutterBottom
						>
							{languageTokens.contactMeInfo}
						</Typography>
						<form id={'contactForm'} onSubmit={this.sendEmail.bind(this)}>
							<Grid container spacing={1}>
								<Grid xs={12} sm={6} item>
									<TextField
										name={"firstName"}
										InputLabelProps={{
											className: classes.labelInput,
										}}
										InputProps={{
											className: classes.input,
										}}
										className={classes.textField}
										placeholder={languageTokens.EnterFirstName}
										label={languageTokens.FirstName}
										variant="outlined"
										fullWidth
										required
										value={this.state.firstName}
										onChange={this.handleChange.bind(this)}
									/>
								</Grid>
								<Grid xs={12} sm={6} item>
									<TextField
										name={"lastName"}
										InputLabelProps={{
											className: classes.labelInput,
										}}
										InputProps={{
											className: classes.input,
										}}
										placeholder={languageTokens.EnterLastName}
										label={languageTokens.LastName}
										variant="outlined"
										fullWidth
										required
										value={this.state.lastName}
										onChange={this.handleChange.bind(this)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name={"email"}
										InputLabelProps={{
											className: classes.labelInput,
										}}
										InputProps={{
											className: classes.input,
										}}
										type="email"
										placeholder={languageTokens.EnterEmail}
										label={languageTokens.Email}
										variant="outlined"
										fullWidth
										required
										value={this.state.email}
										onChange={this.handleChange.bind(this)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name={"subject"}
										InputLabelProps={{
											className: classes.labelInput,
										}}
										InputProps={{
											className: classes.input,
										}}
										placeholder={languageTokens.EnterSubject}
										label={languageTokens.Subject}
										variant="outlined"
										fullWidth
										required
										value={this.state.subject}
										onChange={this.handleChange.bind(this)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name={"message"}
										InputLabelProps={{
											className: classes.labelInput,
										}}
										InputProps={{
											className: classes.input,
										}}
										label={languageTokens.Message}
										multiline
										rows={4}
										placeholder={languageTokens.TypeYourMessageHere}
										variant="outlined"
										fullWidth
										required
										value={this.state.message}
										onChange={this.handleChange.bind(this)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										className={classes.button}
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
									>
										{languageTokens.SUBMIT}
									</Button>
								</Grid>

							</Grid>
						</form>
					</CardContent>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
	setAlert: setAlert,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Contact));
// Redux
import { SET_NEW_SEEDS } from "../types";
import {createSeed} from "../../util/StringUtility";

const seed1String = localStorage.getItem('seed1');
const seed1: number = seed1String !== null ? parseInt(seed1String) :  createSeed();

const seed2String = localStorage.getItem('seed2');
const seed2: number = seed2String !== null ? parseInt(seed2String) :  createSeed();

const seed3String = localStorage.getItem('seed3');
const seed3: number = seed3String !== null ? parseInt(seed3String) :  createSeed();

export interface ISeedState {
	seed1: number,
	seed2: number,
	seed3: number,
}

const initialState: ISeedState = {
	seed1: seed1,
	seed2: seed2,
	seed3: seed3,
};

interface ISnackbarAction extends ISeedState {
	type: string,
}

export default function(state: ISeedState = initialState, action: ISnackbarAction) {

	switch (action.type) {
		case SET_NEW_SEEDS:
			return {
				...state,
				seed1: createSeed(),
				seed2: createSeed(),
				seed3: createSeed(),
			};
		default:
			return state;
	}
}
// React
import * as React from "react";
import {Component, ReactNode} from "react";

// Redux
import { IGlobalState } from "../redux/store";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import {Button, CardMedia, Grid, IconButton, ImageList, ImageListItem, ListSubheader, Typography} from "@mui/material";

// Interfaces
import { WTITheme } from "../interfaces/themes/WTTheme";
import { WTIPageProps } from "../interfaces/components/props/WTIPageProps";
import WTImageGrid from "../components/grids/WTImageGrid";
import {WTIMediaCardElement} from "../interfaces/components/cards/WTIMediaCardElement";
import {ILanguageTokens} from "../localization/LanguageTokens";
import {projects} from "../util/Config";
import {ReplaceSpaces} from "../util/StringUtility";
import {setNewSeeds} from "../redux/seedReducer/seedActions";

const styles: any = ((theme: WTITheme) => ({
	root: {
		minHeight: '100%',
		height: 'auto',
		[theme.breakpoints.only('xs')]: {
			padding: `${theme.spacing(8)} ${theme.spacing(0)} `,
		},
		[theme.breakpoints.up('sm')]: {
			padding: `${theme.spacing(0)} ${theme.spacing(8)} ${theme.spacing(0)} ${theme.spacing(8)}`,
		},
	},
	textContainerWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		placeContent: 'center',
		height: 200,
		padding:  `${theme.spacing(4)} `
	},
	textContainer: {
		width: '100%',
		display: 'grid',
		alignContent: 'center',
		placeItems: 'center',
		textAlign: 'center',
	},
	quoteText: {
		cursor: 'pointer',
	}
}));

interface IProps extends WTIPageProps {
	seed1: number;
	seed2: number;
	seed3: number;
	setNewSeeds: () => void;
}

class Home extends Component<IProps> {

	render() {
		const {classes, languageTokens, seed1, seed2, seed3, setNewSeeds} = this.props;

		// Quote
		const index1 = seed1 % languageTokens.quotes.length
		let quote: string = languageTokens.quotes[index1];
		quote = ReplaceSpaces(quote);
		const quoteParts: string[] = quote.split('\n');

		// Quoter Prefix
		const index2 = seed2 % languageTokens.quoterPrefix.length
		const quoterPrefix = languageTokens.quoterPrefix[index2];

		// Quoter
		const index3 = seed3 % languageTokens.quoter.length
		const quoter: string = languageTokens.quoter[index3];

		return (
			<div className={classes.root}>
				<div className={classes.textContainerWrapper}>
					<div
						className={classes.textContainer}
					>
						{quoteParts.map(part =>
							<Typography
								className={classes.quoteText}
								onClick={setNewSeeds}
								key={part}
								variant={"h3"}
							>{part}
							</Typography>
						)}
						<Typography
							variant={"subtitle2"}
							className={classes.quoter}
						>{`${quoterPrefix}${quoter}, 2021`}
						</Typography>
					</div>
				</div>
				<WTImageGrid projectPages={projects(languageTokens)} />
			</div>
		);
	}

	componentDidMount() {
		this.handleScrollPosition();
		window.addEventListener("scroll", this.handleClick);
	}

	// handle scroll position after content load
	handleScrollPosition = () => {
		const scrollPosition = sessionStorage.getItem("scrollPosition");
		if (scrollPosition) {
			window.scrollTo(0, parseInt(scrollPosition));
			sessionStorage.removeItem("scrollPosition");
		}
	};

	// store position in sessionStorage
	handleClick = e => {
		sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
	};

}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
	seed1: state.seed.seed1,
	seed2: state.seed.seed2,
	seed3: state.seed.seed3,
});

const mapActionsToProps = {
	setNewSeeds,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Home));
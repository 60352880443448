// React
import React, {Component} from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {WTIMediaCardElement} from "../../interfaces/components/cards/WTIMediaCardElement";
import {ILanguageTokens} from "../../localization/LanguageTokens";
import {IGlobalState} from "../../redux/store";
import {connect} from "react-redux";
import {WTITheme} from "../../interfaces/themes/WTTheme";
import WTHistory from "../../WTHistory";
import {ButtonBase} from "@mui/material";

// Images
import { IKImage } from 'imagekitio-react';

const styles: any = ((theme: WTITheme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    cardActions: {
        flex: '1 1',
        padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    },
    button: {
        justifyContent: 'start',
        minWidth: 'fit-content',
        alignSelf: 'self-end',
        padding: `0 ${theme.spacing(1)}`,
        color: theme.palette.primary,
    },
    imageButton: {
        display: 'contents',
        textAlign: 'initial'
    },
    image: {
        imageRendering: '-webkit-optimize-contrast',
    },
}));


interface IProps extends WTIComponentProps {
    mediaCardElement: WTIMediaCardElement,
    languageTokens: ILanguageTokens,
}

class WTMediaCard extends Component<IProps> {

    render() {
        let { classes, mediaCardElement, languageTokens } = this.props;

        return (
            <Card sx={{ minWidth: 300, width: 300, maxWidth: 300 }} elevation={2}  className={classes.root}>
                <ButtonBase
                    className={classes.imageButton}
                    onClick={() => WTHistory.push(mediaCardElement.route)}
                >
                    <CardMedia
                        component={() => <IKImage
                                path={mediaCardElement.thumbnail}
                                loading="lazy"
                                lqip={{ active: true }}
                                className={classes.image}
                                alt={mediaCardElement.thumbnail}
                                src={mediaCardElement.thumbnail}
                                transformation={[{
                                    width: 300,
                                    height: 220,
                                }]}
                            />
                        }
                    />
                </ButtonBase>
                <CardContent
                    className={classes.cardContent}
                >
                    <Typography gutterBottom variant="h5" component="div">{mediaCardElement.name}</Typography>
                    <Typography variant="body2" color="text.primary">{mediaCardElement.description}</Typography>
                </CardContent>
                <CardActions
                    className={classes.cardActions}
                >
                    <Button
                        size="small"
                        className={classes.button}
                        onClick={() => WTHistory.push(mediaCardElement.route)}
                    >{languageTokens.more}
                    </Button>
                </CardActions>
            </Card>
        );
    };
}

const mapStateToProps = (state: IGlobalState) => ({
    languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTMediaCard));
// React
import * as React from "react";
import {Component, ReactNode} from "react";

// Material-Ui
import {AppBar, Toolbar, Menu, MenuItem, Button, Typography} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import { Classes } from "@mui/styles/mergeClasses/mergeClasses";

// Redux
import { connect } from "react-redux";
import { IGlobalState } from "../../redux/store";
import { ILanguageState } from "../../redux/language/languageReducer";
import { setLanguage } from "../../redux/language/languageActions";

// Localization
import { ILanguageTokens } from "../../localization/LanguageTokens";

// Interfaces
import {WTITheme} from "../../interfaces/themes/WTTheme";

// Images
import iconCollapsedMenu from "../../images/icons/iconCollapsedMenu.png";
import WTLanguageSelect from "../select/WTLanguageSelect";
import WTThemeSwitch from "../switch/WTThemeSwitch";

import WTHistory from "../../WTHistory";
import {routes} from "../../routing/Routes";
import WTButton from "../buttons/WTButton";
import {WTIProjectPage} from "../../interfaces/components/pages/projects/WTIProjectPage";
import {WTINavBarElement} from "../../interfaces/components/NavBar/WTINavBarElement";
import {setTheme} from "../../redux/themeReducer/themeActions";

const styles: any = ((theme: WTITheme) => ({
	root: {
		position: 'relative',
		// padding: "0 !important",
	},
	toolbar: {
		display: 'grid',
		padding: `0 ${theme.spacing(1)}`,
		backgroundColor: theme.palette.primary.main,
	},
	row: {
		width: '100%',
		display: 'flex',
		marginLeft: 'auto',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'end',
		},
	},
	logoAndTextContainer: {
		display: 'flex',
	},
	logoText: {
		paddingLeft: theme.spacing(0.75),
		alignSelf: 'center',
		color: theme.palette.text.secondary,
		fontSize: 30,
		textDecoration: 'none',
	},
	grid: {
		display: "flex",
	},
	burgerMenu: {
		display: 'contents',
		textAlign: 'right',
		paddingRight: theme.spacing(1),
	},
	pages: {
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	collapsedMenuButton: {
		[theme.breakpoints.only('xs')]: {
			maxWidth: 20,
			width: 20,
			minWidth: 20,
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: 30,
			width: 30,
			minWidth: 30,
		},
	},
	logo: {
		height: 40,
		width: 40,
		alignSelf: 'center',
	},
	menu: {
		color: theme.palette.text.secondary,
	},
	menuItem: {
		color: '#222222',
	}
}));

interface IProps {
	classes: Classes,
	language: ILanguageState;
	languageTokens: ILanguageTokens;
	dark: boolean;
	setIsDark: (isDark: boolean) => void;
}

interface IState {
	menuCollapsed: boolean;
	anchorEl?: HTMLElement;
}

class WTNavBar extends Component<IProps> {

	state: IState = {
		menuCollapsed: false,
		anchorEl: undefined,
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		const { menuCollapsed } = this.state;
		if (menuCollapsed && window.innerWidth >= 600) {
			this.setState({ menuCollapsed: false });
		}
		else if (!menuCollapsed && window.innerWidth < 600) {
			this.setState({ menuCollapsed: true });
		}
	};

	handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: undefined });
	};

	logoAndLogoText = (): ReactNode => {
		const { classes, languageTokens } = this.props;

		return (
			<Grid item className={classes.logoAndTextContainer}>
					<WTButton
						textButton
						classes={{root: classes.logoText}}
						onClick={() => WTHistory.push(routes.index)}
					>
						<Typography variant={"h4"}>
							{languageTokens.AppName}
						</Typography>
					</WTButton>
			</Grid>
		);
	}

	MenuUncollapsed = (pages: WTINavBarElement[]) => {
		const { classes } = this.props;
		return 	(
			<Grid item className={classes.pages} xs={12} sm={12} md={12}>
				{pages.map(p =>
					<WTButton
						key={p.name}
						textButton
						classes={{root: classes.menu}}
						onClick={() => WTHistory.push(p.route)}
					>
						<Typography variant={"h4"}>
							{p.name}
						</Typography>
					</WTButton>
				)}
			</Grid>
		)
	};

	MenuCollapsed = (pages: WTINavBarElement[]) => {
		const { anchorEl } = this.state;
		const { classes } = this.props;

		return <Grid item className={classes.burgerMenu}>
			<Button
				className={classes.collapsedMenuButton}
				onClick={this.handleClick}>
				<img className={classes.collapsedMenuButton} src={iconCollapsedMenu} alt={"iconCollapsedMenu"}/>
			</Button>
			<Menu
				id="collapsedMenu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={this.handleClose}
			>
				{pages.map((p, index) =>
					<MenuItem
						key={`${p.name}_${index}`}
						onClick={() => WTHistory.push(p.route)}
						className={classes.menuItem}
					>
						<Typography>
							{p.name}
						</Typography>
					</MenuItem>
				)}
			</Menu>
		</Grid>;
	};

	getMenu = (pages: WTINavBarElement[]): ReactNode => {
		const { classes, dark, setIsDark } = this.props;
		const { menuCollapsed } = this.state;

		return menuCollapsed ?
			<div className={classes.row}>
				<WTLanguageSelect/>
				<WTThemeSwitch dark={dark} setIsDark={setIsDark}/>
				{this.MenuCollapsed(pages)}
			</div>
			:
			<div className={classes.row}>
				{this.MenuUncollapsed(pages)}
				<WTLanguageSelect/>
				<WTThemeSwitch dark={dark} setIsDark={setIsDark}/>
			</div>
	}

	render() {
		const {languageTokens, classes } = this.props;

		const pages: WTINavBarElement[] = [
			{
				name: languageTokens.About,
				route: routes.about,
			},
			{
				name: languageTokens.Contact,
				route: routes.contact
			}
		]
		return (
			<AppBar className={classes.root} color='transparent'>
				<Toolbar className={classes.toolbar} variant={"dense"}>
					<Grid className={classes.grid} >
						{this.logoAndLogoText()}
						{this.getMenu(pages)}
					</Grid>
				</Toolbar>
			</AppBar>
		);
	}
}

const mapStateToProps = (state: IGlobalState) => ({
	language: state.language,
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
	setLanguage,
};


export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTNavBar));
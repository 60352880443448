// React
import React, { Component } from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {WTITheme} from "../../interfaces/themes/WTTheme";

// Images
import { ILanguageTokens } from "../../localization/LanguageTokens";
import { IGlobalState } from "../../redux/store";
import { connect } from "react-redux";
import {FormControlLabel, Switch} from "@mui/material";
import {setTheme} from "../../redux/themeReducer/themeActions";

const styles: any = ((theme: WTITheme) => ({
	root: {
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
		'& .MuiSwitch-switchBase': {
			'&.Mui-checked': {
				color: '#fff',
				'& .MuiSwitch-thumb:before': {
				},
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : theme.palette.text.secondary,
				},
			},
		},
		'& .MuiFormControlLabel-label': {
			width: 50,
		}
	},
}));

interface IProps extends WTIComponentProps {
	languageTokens: ILanguageTokens;
	dark: boolean;
	setIsDark: (dark: boolean) => void;
	setTheme: (dark: boolean) => void;
}

class WTThemeSwitch extends Component<IProps> {

	render() {
		const { classes, languageTokens, dark, setIsDark, setTheme } = this.props;

		const languageText = dark ? languageTokens.DarkMode : languageTokens.BrightMode;
		return <FormControlLabel
			className={classes.root}
			control={<Switch checked={dark}/>}
			label={languageText}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				setTheme(e.target.checked);
				setIsDark(e.target.checked);
			}}
			color={'theme.palette.secondary'}
		/>
	};
}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
	setTheme,
};


export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTThemeSwitch));
// React
import * as React from "react";
import { Component } from "react";

// Redux
import { IGlobalState } from "../redux/store";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import {Grid, Typography} from "@mui/material";

// Interfaces
import { WTITheme } from "../interfaces/themes/WTTheme";
import { WTIPageProps } from "../interfaces/components/props/WTIPageProps";
import {ILanguageTokens} from "../localization/LanguageTokens";

const styles: any = ((theme: WTITheme) => ({
	root: {
		minHeight: '-webkit-fill-available',
		height: 'auto',
	},
	textContainerWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		placeContent: 'center',
		height: 400,
	},
	textContainer: {
		width: '100%',
		display: 'grid',
		alignContent: 'center',
		placeItems: 'center',
		textAlign: 'center',
	},
	header: {
		paddingBottom: theme.spacing(4),
	}
}));

interface IProps extends WTIPageProps {
}

class LegalNotice extends Component<IProps> {
	render() {
		const {classes, languageTokens } = this.props;

		const name = `${languageTokens.firstName} ${languageTokens.secondName}`;

		return (
			<div className={`${classes.root}`}>
				<div className={classes.textContainerWrapper}>
					<div className={classes.textContainer}>
						<Typography
							variant={"h1"}
							className={classes.header}
						>{languageTokens.LegalNotice}
						</Typography>
						<Typography variant={"subtitle1"}>{name} ({languageTokens.myCity})</Typography>
						<Typography variant={"subtitle1"}>{languageTokens.email}</Typography>
						{languageTokens.companyName.length  == 0 ?
							null : <Typography variant={"subtitle1"}>{languageTokens.companyName}</Typography>
						}
						<br/>
						{languageTokens.addressLine1.length  == 0 ?
							null : <Typography variant={"subtitle1"}>{languageTokens.addressLine1}</Typography>
						}
						{languageTokens.addressLine2.length  == 0 ?
							null : <Typography variant={"subtitle1"}>{languageTokens.addressLine2}</Typography>
						}
						{languageTokens.addressLine3.length  == 0 ?
							null : <Typography variant={"subtitle1"}>{languageTokens.addressLine3}</Typography>
						}
						{languageTokens.addressLine4.length  == 0 ?
							null : <Typography variant={"subtitle1"}>{languageTokens.addressLine4}</Typography>
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IGlobalState) => ({
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(LegalNotice));
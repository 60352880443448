// Redux
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import alertReducer, { IAlertState } from "./alert/alertReducer";
import languageReducer, { ILanguageState } from "./language/languageReducer";
import uiReducer, { IUiState } from "./ui/uiReducer";
import overlayReducer, {IOverlayState} from "./overlay/overlayReducer";
import seedReducer, {ISeedState} from "./seedReducer/seedReducer";
import themeReducer, {IThemeState} from "./themeReducer/themeReducer";

// GLOBALIZED STATE
export interface IGlobalState {
	ui: IUiState,
	overlay: IOverlayState,
	alert: IAlertState,
	language: ILanguageState,
	seed: ISeedState,
	theme: IThemeState,
}

const configureStore = (browserHistory: any) => {
	const initialState: any = {};

	const reducers = combineReducers({
		ui: uiReducer,
		overlay: overlayReducer,
		alert: alertReducer,
		language: languageReducer,
		seed: seedReducer,
		theme: themeReducer,
	});

	const myRouterMiddleware = routerMiddleware(browserHistory);
	const middleware = [thunk, myRouterMiddleware];

	return createStore(
		reducers,
		initialState,
		compose(
			applyMiddleware(...middleware),
			//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);
}
export default configureStore;

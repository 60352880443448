import {WTIPaletteOptions} from "../interfaces/themes/WTTheme";

export const BrightPalette: WTIPaletteOptions = {
	primary: {
		main: '#FF4F4F',
		light: '#ffa270',
		dark: '#c63f17',
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#FFFFFF',
		light: '#D800FF',
		dark: '#D800FF',
		contrastText: "#D800FF"
	},
	tertiary: {
		main: '#EEEEEE',
		light: '#D800FF',
		dark: '#D800FF',
		border: '#D800FF',
		contrastText: "#D800FF"
	},
	text: {
		primary: '#222222',
		secondary: '#FFFFFF',
		tertiary: '#222222',
		quaternary: '#444444',
		disabled: '#D800FF',
		hint: '#D800FF',
	},
};
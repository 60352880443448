// React
import React, {Component, ReactNode, SyntheticEvent} from "react";

import withStyles from '@mui/styles/withStyles';
import { Button } from "@mui/material";

// Interfaces
import {WTITheme} from "../../interfaces/themes/WTTheme";
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import { WTIButtonType } from "../../interfaces/components/buttons/WTIButtonType";

const styles: any = ((theme: WTITheme) => ({
	root: {
		[theme.breakpoints.only('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 18,
		},
		margin: "0 4px",
		minWidth: "fit-content",
		placeSelf: "center",
		lineHeight: 1,
		borderRadius: theme.shape.borderRadius,
		textTransform: "none",

		whiteSpace: 'nowrap',
	},
	textButton: {
		height: 42,
		minHeight: 42,
		maxHeight: 42,
		alignSelf: 'center',
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: "transparent",
		},
		"&:active": {
			backgroundColor: "transparent",
		},
	},
	buttonBgDefault: {
		backgroundColor: theme.palette.action.active,
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
		"&:active": {
			backgroundColor: theme.palette.action.selected,
		},
	},
}));

interface IProps extends WTIComponentProps {
	children?: ReactNode;
	onClick?: (event: SyntheticEvent) => void;
	disabled?: boolean;
	loading?: boolean;
	darkShadow?: boolean;
	textButton?: boolean;
	buttonType?: WTIButtonType;
	classNames?: [];
}

class WTButton extends Component<IProps> {

	render() {
		let { classes, onClick, disabled, loading, buttonType, textButton, children } = this.props;

		// Set BgColor
		let buttonBgClass;
		if (buttonType === WTIButtonType.Confirm) buttonBgClass = classes.buttonBgConfirm;
		else if (buttonType === WTIButtonType.Cancel) buttonBgClass = classes.buttonBgCancel;
		else buttonBgClass = classes.buttonBgDefault;

		return (
			<Button
				type={!onClick ? 'submit' : undefined}
				disabled={disabled || loading}
				onClick={onClick}
				className={`
					${classes.root} 
					${textButton ? classes.textButton: buttonBgClass} 
				`}
			>
				{children}
			</Button>
		);
	};
}

export default (withStyles(styles)(WTButton));
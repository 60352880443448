// React
import React, { Component } from "react";

import withStyles from '@mui/styles/withStyles';

// Interfaces
import { WTIComponentProps } from "../../interfaces/components/props/WTIComponentProps";
import {WTITheme} from "../../interfaces/themes/WTTheme";

// Images
import { ILanguageTokens } from "../../localization/LanguageTokens";
import { IGlobalState } from "../../redux/store";
import { connect } from "react-redux";
import {setLanguage} from "../../redux/language/languageActions";
import WTSelect from "./WTSelect";
import {ILanguageState} from "../../redux/language/languageReducer";

const styles: any = ((theme: WTITheme) => ({
	root: {
		alignSelf: 'center',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
		"&:hover": {

			color: theme.palette.text.primary
		},
		"&:active": {

			color: theme.palette.text.primary
		},
	},
}));

interface IProps extends WTIComponentProps {
	language: ILanguageState;
	languageTokens: ILanguageTokens;
	setLanguage: (value: string) => void;
}

class WTLanguageSelect extends Component<IProps> {

	render() {
		const { classes, language: { activeLanguage, availableLanguages }, setLanguage } = this.props;

		return <WTSelect
					classes={{root: classes.root}}
					localizeTexts
					value={activeLanguage}
					texts={availableLanguages}
					onSelect={(language: string) => {
						setLanguage(language);
					}}
				/>
			};
}

const mapStateToProps = (state: IGlobalState) => ({
	language: state.language,
	languageTokens: state.language.languageTokens,
});

const mapActionsToProps = {
	setLanguage,
};


export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WTLanguageSelect));
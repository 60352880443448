// Redux
import {SET_THEME} from "../types";

let isDark: boolean = localStorage.getItem('isDark') === "1";
export interface IThemeState {
	isDark: boolean,
}

const initialState: IThemeState = {
	isDark,
};

interface ISnackbarAction extends IThemeState {
	type: string,
}

export default function(state: IThemeState = initialState, action: ISnackbarAction) {
	const {isDark} = action;

	switch (action.type) {
		case SET_THEME:
			localStorage.setItem('isDark', isDark ? "1" : "0");
			return {
				...state,
				isDark: isDark
			};
		default:
			return state;
	}
}